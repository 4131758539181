//design-system-components@2.14.1
import { forwardRef } from 'react'
import classNames from 'classnames'
import FlightIcon from '../flight-icon'
import Interactive from '../interactive'
import { type ReactNode } from 'react'
import type { InteractiveProps } from '../interactive'

export const DEFAULT_ICONPOSITION = 'leading'
export const DEFAULT_COLOR = 'primary'
export const DEFAULT_SIZE = 'medium'
export const ICONPOSITIONS = ['leading', 'trailing'] as const
export const COLORS = ['primary', 'secondary', 'custom'] as const
export const SIZES = ['small', 'medium', 'large'] as const

export interface StandaloneLinkProps extends InteractiveProps {
	text: string
	size?: (typeof SIZES)[number]
	color?: (typeof COLORS)[number]
	icon: ReactNode
	iconPosition?: (typeof ICONPOSITIONS)[number]
	href?: string
	locale?: string
	isHrefExternal?: boolean
	onClick?: () => void
	className?: string
	['data-testid']?: string
}

const StandaloneLink = forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	StandaloneLinkProps
>(
	(
		{
			text,
			size = DEFAULT_SIZE,
			color = DEFAULT_COLOR,
			icon,
			iconPosition = DEFAULT_ICONPOSITION,
			className,
			'data-testid': dataTestId,
			locale,
			...props
		},
		ref
	) => {
		return (
			<Interactive
				className={classNames(
					'hds-link-standalone',
					`hds-link-standalone--size-${size}`,
					`hds-link-standalone--icon-position-${iconPosition}`,
					color !== 'custom' && `hds-link-standalone--color-${color}`,
					className
				)}
				data-testid={dataTestId}
				locale={locale}
				{...props}
				ref={ref}
			>
				{iconPosition === 'leading' ? (
					<>
						<div className="hds-link-standalone__icon">
							{typeof icon === 'string' ? (
								<FlightIcon
									name={icon}
									size={size === 'large' ? 24 : 16}
									stretched
								/>
							) : (
								icon
							)}
						</div>
						<div className="hds-link-standalone__text">{text}</div>
					</>
				) : (
					<>
						<div className="hds-link-standalone__text">{text}</div>
						<div className="hds-link-standalone__icon">
							{typeof icon === 'string' ? (
								<FlightIcon
									name={icon}
									size={size === 'large' ? 24 : 16}
									stretched
								/>
							) : (
								icon
							)}
						</div>
					</>
				)}
			</Interactive>
		)
	}
)

StandaloneLink.displayName = 'StandaloneLink'

export default StandaloneLink
