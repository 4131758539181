'use client'

import { ReactNode, useEffect } from 'react'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { Router } from 'next/router'
import useDatagrailPerformanceConsent from './use-datagrail-performance-consent'
import initializePosthog from './initialize-posthog'

// Due to PostHog being a performance tool, we can only initialize if the user provides consent
// for performance cookies. Using the Datagrail event listeners, we check the user's consent
// preferences and only render the PostHog provider if they've consented to performance cookies.
export default function PostHogProviderPagesRouter({
	children,
}: {
	children: ReactNode
}) {
	const categoryPerformanceConsent = useDatagrailPerformanceConsent()

	useEffect(() => {
		if (categoryPerformanceConsent) {
			initializePosthog('pagesRouter')

			const handleRouteChange = () => posthog?.capture('$pageview')

			Router.events.on('routeChangeComplete', handleRouteChange)

			return () => {
				Router.events.off('routeChangeComplete', handleRouteChange)
			}
		}
	}, [categoryPerformanceConsent])

	if (categoryPerformanceConsent) {
		return <PostHogProvider client={posthog}>{children}</PostHogProvider>
	} else {
		return children
	}
}
