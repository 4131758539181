import {
	createElement,
	type ComponentPropsWithRef,
	type ElementType,
} from 'react'
import { Text, type BaseTextProps } from './base'

interface BodyProps<T extends ElementType> extends BaseTextProps<T> {
	size?: '200' | '300' | '400'
}

export function Body<T extends ElementType>({
	size = '300',
	...rest
}: BodyProps<T> & Omit<ComponentPropsWithRef<T>, keyof BodyProps<T>>) {
	return createElement(Text, {
		...rest,
		size,
		group: 'hdsplus-body',
	})
}

interface DisplayExpressiveProps<T extends ElementType>
	extends BaseTextProps<T> {
	size?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800'
}

export function DisplayExpressive<T extends ElementType>({
	size = '200',
	...rest
}: DisplayExpressiveProps<T> &
	Omit<ComponentPropsWithRef<T>, keyof DisplayExpressiveProps<T>>) {
	return createElement(Text, {
		...rest,
		size,
		group: 'hdsplus-display-expressive',
	})
}

type LabelProps<T extends ElementType> = Omit<BaseTextProps<T>, 'size'>

export function Label<T extends ElementType>(
	props: LabelProps<T> & Omit<ComponentPropsWithRef<T>, keyof LabelProps<T>>
) {
	return createElement(Text, {
		...props,
		group: 'hdsplus-label',
	})
}
