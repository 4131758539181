import Text from '../../text'

export default function Label({ label }: { label: string }) {
	return (
		<Text.Display
			className="hds-form-radio-card__label"
			size="300"
			weight="bold"
		>
			{label}
		</Text.Display>
	)
}
