import Link from 'next/link'
import { vercelSplit } from '../../../../lib/v'
import { getLocalizedLinkProps } from '../../../../lib/get-localized-link-props'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import s from './style.module.css'

export interface LinkListProps {
	locale?: string
	links: {
		title: string
		url: string
		icon?: string
		description?: string
		iconGradient?: string
	}[]
	linkClickHandler?: (title: string, url: string) => void
}

const GRADIENT_VALUE_MAP = new Map([
	[
		'ILM',
		{
			iconGradient: 'var(--icon-gradient-infrastructure-lifecycle-management)',
		},
	],
	[
		'SLM',
		{
			iconGradient: 'var(--icon-gradient-security-lifecycle-management)',
		},
	],
	[
		'Cloud',
		{
			iconGradient: 'var(--icon-gradient-infrastructure-cloud)',
		},
	],
])

export default function LinkList({
	locale,
	links,
	linkClickHandler,
}: LinkListProps) {
	return (
		<ul className={s.linkList}>
			{links.map((link) => {
				let style
				link.iconGradient
					? (style = {
							'--iconGradient': GRADIENT_VALUE_MAP.get(
								vercelSplit(link.iconGradient)
							)?.iconGradient,
					  } as React.CSSProperties)
					: (style = null)
				return (
					<li key={link.title}>
						<NavigationMenu.Link asChild>
							<Link
								className={s.link}
								{...getLocalizedLinkProps(link.url, locale)}
								onClickCapture={() => {
									linkClickHandler && linkClickHandler(link.title, link.url)
								}}
							>
								{link.iconGradient && link.icon ? (
									<div className={s.iconBackground} style={style}>
										<FlightIcon name={vercelSplit(link.icon)} />
									</div>
								) : (
									link.icon && (
										<FlightIcon
											name={vercelSplit(link.icon)}
											color="var(--token-color-foreground-faint)"
										/>
									)
								)}
								<div>
									<div className={link.iconGradient && s.boldTitle}>
										{link.title}
									</div>
									{link.description && (
										<div className={s.description}>{link.description}</div>
									)}
								</div>
							</Link>
						</NavigationMenu.Link>
					</li>
				)
			})}
		</ul>
	)
}
