import Image from 'next/image'
import classNames from 'classnames'
import { vercelSplit } from '@web/cms'
import LogoCorporate from '@hashicorp/web-mktg-logos/src/hashicorp/hashicorp-horizontal_on-dark.svg'
import InlineLink from '@hashicorp/react-hds/src/components/inline-link'
import { TextPlus } from '@hashicorp/react-hds/src/components/text'
import { parseUrl } from '@hashicorp/react-components/src/lib/parse-url'
import { ConsentManagerButton, ConsentManager } from '../consent-manager'
import PrefooterNewsletterForm from 'components/materia/prefooter-newsletter-form'
import SocialBlock from 'components/materia/social-block'
import LocaleSwitcher from 'components/locale-switcher'
import type { FooterFieldsFragment } from 'types/dato'
import s from './style.module.css'

interface FooterProps extends FooterFieldsFragment {
	/**
	 * Whether to display the newsletter signup form at the top of the footer
	 * @default true
	 */
	showSignupForm?: boolean
	locale: string
}

function Footer({
	socialNetworkBlock,
	legalLinks,
	linkLists,
	showSignupForm = true,
	locale,
}: FooterProps) {
	return (
		<footer
			className={classNames(s.footer, {
				[s.hasPrefooter]: showSignupForm,
			})}
			data-theme="dark"
		>
			<ConsentManager />
			{showSignupForm && <PrefooterNewsletterForm />}
			<PrimaryLinksSection
				linkLists={linkLists}
				showSignupForm={showSignupForm}
				locale={locale}
			/>
			<nav className={s.bottom} aria-label="footer legal and social links">
				<LegalLinksSection legalLinks={legalLinks} locale={locale} />
				<SocialBlock {...socialNetworkBlock} className={s.socialLinks} />
			</nav>
		</footer>
	)
}

function PrimaryLinksSection({
	linkLists,
	showSignupForm,
	locale,
}: Pick<FooterProps, 'linkLists' | 'showSignupForm' | 'locale'>) {
	return (
		<div className={s.primaryLinksWrapper}>
			<nav
				className={classNames(s.primaryLinks, {
					[s.hasPrefooter]: showSignupForm,
				})}
				aria-label="footer primary links"
			>
				<div className={s.logoWrapper}>
					<Image src={LogoCorporate} alt="HashiCorp logo" className={s.logo} />
				</div>
				<div className={s.linkLists}>
					{linkLists.map(({ heading, links }) => (
						<div key={heading} className={s.list}>
							<TextPlus.Label color="strong" weight="semibold">
								{heading}
							</TextPlus.Label>
							<ul className={s.links} aria-label={heading}>
								{links.map(({ text, href }, idx) => (
									<li key={`primaryListLink-${idx}`} className={s.linkListItem}>
										<InlineLink
											href={parseUrl(vercelSplit(href)).href}
											locale={locale}
											prefetch={false}
											text={text}
											className={s.link}
											data-ga-footer={`${heading} | ${vercelSplit(text)}`}
										/>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
				<LocaleSwitcher />
			</nav>
		</div>
	)
}

function LegalLinksSection({
	legalLinks,
	locale,
}: Pick<FooterProps, 'legalLinks' | 'locale'>) {
	return (
		<ul className={s.legalLinks}>
			<li className={s.linkListItem}>
				<InlineLink
					href="https://status.hashicorp.com/"
					text="System Status"
					className={s.link}
					data-ga-footer="Bottom | System Status"
					prefetch={false}
				/>
			</li>
			<li className={s.linkListItem}>
				<ConsentManagerButton />
			</li>
			{legalLinks.map(({ href, text }, idx) => (
				<li key={`legalLink-${idx}`} className={s.linkListItem}>
					<InlineLink
						href={parseUrl(vercelSplit(href)).href}
						locale={locale}
						text={text}
						className={s.link}
						data-ga-footer={`Bottom | ${vercelSplit(text)}`}
						prefetch={false}
					/>
				</li>
			))}
		</ul>
	)
}

export default Footer
