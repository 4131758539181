//design-system-components@3.4.1
import classNames from 'classnames'
import { ReactNode, useId } from 'react'
import Legend from '../legend'
import HelperText from '../helper-text'
import Error from '../error'

interface FieldsetProps {
	isRequired?: boolean
	isOptional?: boolean
	legend?: ReactNode
	helperText?: ReactNode
	error?: ReactNode
	className?: string
	layout?: 'vertical' | 'horizontal'
	children: ReactNode
	id?: string
	testingKey?: string
}

export default function Fieldset({
	isRequired,
	isOptional,
	legend,
	helperText,
	error,
	className,
	layout,
	children,
	id,
	testingKey,
	...rest
}: FieldsetProps) {
	const generatedId = useId()
	const fieldsetId = id ?? generatedId

	return (
		<fieldset
			className={classNames(
				'hds-form-group',
				{ [`hds-form-group--layout-${layout}`]: layout },
				className
			)}
			id={fieldsetId}
			data-testid={testingKey}
			{...rest}
		>
			{legend && (
				<Legend
					isOptional={isOptional}
					isRequired={isRequired}
					className="hds-form-group__legend"
				>
					{legend}
				</Legend>
			)}
			{helperText && (
				<HelperText
					controlId={`${fieldsetId}-help`}
					className="hds-form-group__helper-text"
				>
					{helperText}
				</HelperText>
			)}
			<div className="hds-form-group__control-fields-wrapper">{children}</div>
			{error && (
				<Error
					controlId={`${fieldsetId}-error`}
					className="hds-form-group__error"
				>
					{error}
				</Error>
			)}
		</fieldset>
	)
}
