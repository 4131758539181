import { type ForwardRefExoticComponent, forwardRef } from 'react'
import { IconArrowRight16 } from '@hashicorp/flight-icons/svg-react/arrow-right-16'
import { ButtonProps } from './types'
import { determineColor, determineIconFromLinktype } from './utils'
import { HDSPlusStandaloneLink } from '../../hds-plus/standalone-link'
import { HDSPlusButton } from '../../hds-plus/button'

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
	(
		{
			locale,
			text,
			title,
			url,
			href,
			linkType,
			onClick,
			external,
			className,
			size,
			icon,
			iconPosition = 'trailing',
			label,
			disabled,
			type,
			theme,
		},
		ref
	) => {
		const color = determineColor(theme)

		if (color === 'tertiary') {
			// We prefer the Standalone Link component over the tertiary
			// button styles.
			return (
				<HDSPlusStandaloneLink
					text={(text || title) as string}
					color={
						theme?.background === 'dark' ? 'secondary-inverted' : 'secondary'
					}
					href={url || href}
					icon={
						linkType
							? determineIconFromLinktype(linkType)
							: icon ?? (
									<IconArrowRight16
										style={{ display: 'block' }}
										width="100%"
										height="100%"
									/>
							  )
					}
					iconPosition={linkType ? 'trailing' : iconPosition}
					className={className}
					onClick={onClick as () => void}
					size={size}
					isHrefExternal={linkType === 'outbound' || external}
					ref={ref as ForwardRefExoticComponent<HTMLAnchorElement>}
					locale={locale}
				/>
			)
		}

		return (
			<HDSPlusButton
				text={text || title}
				href={url || href}
				isHrefExternal={linkType === 'outbound' || external}
				color={determineColor(theme)}
				type={type}
				icon={linkType ? determineIconFromLinktype(linkType) : icon}
				iconPosition={linkType ? 'trailing' : iconPosition}
				onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
				className={className}
				// Default buttons to the large size unless we specifically request the
				// small variant
				size={size === 'small' ? 'medium' : 'large'}
				aria-label={label}
				disabled={disabled}
				ref={ref as ForwardRefExoticComponent<HTMLButtonElement>}
				locale={locale}
			/>
		)
	}
)

Button.displayName = 'Button'

export { Button }
