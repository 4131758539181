import { trackNavClickEvent } from '../../../lib/track-nav-click-event'
import * as NavPanel from '../components/panel'
import LinkList, { type LinkListProps } from '../components/link-list'
import Promo, { type PromoProps } from '../components/promo'
import s from './style.module.css'

export interface NavItem {
	navItems: LinkListProps['links']
}

interface StandardPanelProps {
	navData: NavItem[]
	promo?: PromoProps
	sectionName?: string
	locale?: any
}

const StandardPanel = ({
	navData,
	promo,
	sectionName = '',
	locale,
}: StandardPanelProps) => {
	return (
		<NavPanel.Root>
			<div className={s.columns}>
				{navData.map(({ navItems }, stableIdx) => {
					return (
						<NavPanel.Column key={stableIdx}>
							<LinkList
								locale={locale}
								links={navItems}
								linkClickHandler={(text, href) => {
									trackNavClickEvent(text, href, sectionName)
								}}
							/>
						</NavPanel.Column>
					)
				})}
			</div>

			{promo ? <Promo {...promo} /> : null}
		</NavPanel.Root>
	)
}

export default StandardPanel
