import classNames from 'classnames'
import { HTMLProps, ReactNode, useId } from 'react'
import Field from '../field'

interface TextAreaBaseProps {
	isInvalid?: boolean
	isLoading?: boolean
	required?: boolean
	className?: string
	id: string
	field: HTMLProps<HTMLTextAreaElement>
	size: 'medium' | 'large'
	error?: ReactNode
}

function TextAreaBase({
	isInvalid,
	isLoading,
	className,
	id,
	field,
	size,
	error,
	...rest
}: TextAreaBaseProps) {
	return (
		<textarea
			id={id}
			className={classNames(
				'hds-form-textarea',
				`hds-form-textarea--size-${size}`,
				'hds-font-weight-regular',
				{
					['hds-typography-body-200']: size === 'medium',
					['hds-typography-body-300']: size === 'large',
					['hds-form-textarea--is-invalid']: isInvalid,
				},
				className
			)}
			aria-describedby={`${error ? 'error' : 'helper-text'}-${id}`}
			{...field}
			{...rest}
		/>
	)
}

interface TextAreaFieldProps {
	isInvalid?: boolean
	isLoading?: boolean
	isRequired?: boolean
	isOptional?: boolean
	id?: string
	label?: ReactNode
	helperText?: ReactNode
	error?: ReactNode
	field: HTMLProps<HTMLTextAreaElement>
	size?: 'medium' | 'large'
}

export default function TextAreaField({
	isInvalid,
	isLoading,
	isRequired,
	isOptional,
	id,
	label,
	helperText,
	error,
	field,
	size = 'medium',
	...rest
}: TextAreaFieldProps) {
	const generatedId = useId()
	const inputId = id ?? generatedId

	return (
		<Field
			label={label}
			helperText={helperText}
			error={error}
			isRequired={isRequired}
			isOptional={isOptional}
			id={inputId}
			layout="vertical"
		>
			<TextAreaBase
				id={inputId}
				isInvalid={isInvalid}
				isLoading={isLoading}
				required={isRequired}
				field={field}
				size={size}
				{...rest}
			/>
		</Field>
	)
}
