import { TextPlus } from '@hashicorp/react-hds/src/components/text'
import { Submit } from './submit'
import s from './style.module.css'

export type SubmissionStatus = 'error' | 'success'

export default function PrefooterNewsletterForm() {
	return (
		<div
			data-test-id="newsletterSignUpForm"
			className={s.prefooterNewsletterForm}
		>
			<div className={s.content}>
				<TextPlus.DisplayExpressive
					color="strong"
					weight="bold"
					className={s.heading}
				>
					Sign up for HashiCorp news
				</TextPlus.DisplayExpressive>
				<Submit />
			</div>
		</div>
	)
}
