import { forwardRef } from 'react'
import { ReactNode, type MouseEventHandler } from 'react'
import FlightIcon from '../flight-icon'
import Interactive from '../interactive'
import classNames from 'classnames'

export const DEFAULT_SIZE = 'medium'
export const DEFAULT_COLOR = 'primary'
export const DEFAULT_ICONPOSITION = 'leading'
export const SIZES = ['small', 'medium', 'large'] as const
export const COLORS = [
	'primary',
	'secondary',
	'tertiary',
	'critical',
	'custom',
] as const
type ButtonColor = (typeof COLORS)[number]
export const ICONPOSITIONS = ['leading', 'trailing'] as const

export interface ButtonProps {
	size?: (typeof SIZES)[number]
	color?: ButtonColor
	isFullWidth?: boolean
	isIconOnly?: boolean
	icon?: ReactNode
	iconPosition?: (typeof ICONPOSITIONS)[number]
	text?: string
	href?: string
	isHrefExternal?: boolean
	onClick?: MouseEventHandler<HTMLButtonElement>
	className?: string
	locale?: string
	disabled?: boolean
	autoFocus?: boolean
	type?: 'button' | 'submit' | 'reset'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			size = DEFAULT_SIZE,
			color = DEFAULT_COLOR,
			isFullWidth,
			isIconOnly,
			icon,
			iconPosition = DEFAULT_ICONPOSITION,
			text,
			href,
			isHrefExternal,
			className,
			locale,
			...rest
		},
		ref
	) => {
		return (
			<Interactive
				className={classNames(
					'hds-button',
					`hds-button--size-${size}`,
					{ ['hds-button--width-full']: isFullWidth },
					{ ['hds-button--is-icon-only']: isIconOnly },
					color !== 'custom' && `hds-button--color-${color}`,
					className
				)}
				href={rest.disabled ? '' : href}
				isHrefExternal={isHrefExternal}
				aria-label={isIconOnly ? text : undefined}
				{...rest}
				locale={locale}
				ref={ref}
			>
				{buildButtonJSX({ isIconOnly, icon, iconPosition, text, size })}
			</Interactive>
		)
	}
)
Button.displayName = 'Button'

export default Button

const buildButtonJSX = ({
	isIconOnly,
	icon,
	iconPosition,
	text,
	size,
}: Pick<
	ButtonProps,
	'isIconOnly' | 'icon' | 'iconPosition' | 'text' | 'size'
>) => {
	if (isIconOnly && !icon) {
		throw new Error('Icon is required when isIconOnly is true')
	}
	if (!icon) {
		return <div className="hds-button__text">{text}</div>
	}

	if (isIconOnly) {
		return <div className="hds-button__icon">{icon}</div>
	}

	if (icon) {
		return iconPosition === 'leading' ? (
			<>
				<div className="hds-button__icon">
					{typeof icon === 'string' ? (
						<FlightIcon
							name={icon}
							size={size === 'large' ? 24 : 16}
							stretched
						/>
					) : (
						icon
					)}
				</div>
				<div className="hds-button__text">{text}</div>
			</>
		) : (
			<>
				<div className="hds-button__text">{text}</div>
				<div className="hds-button__icon">
					{typeof icon === 'string' ? (
						<FlightIcon
							name={icon}
							size={size === 'large' ? 24 : 16}
							stretched
						/>
					) : (
						icon
					)}
				</div>
			</>
		)
	}

	return <div className="hds-button__text">{text}</div>
}
