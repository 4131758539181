const SHARED_CONFIG = {
	applicationId: '104ed401-4d33-48f1-8947-64787059a0a2',
	clientToken: 'pub99e26adad99a9c886de2f9522491f82a',
	site: 'datadoghq.com',
	service: 'www.hashicorp.com',
	version: process.env.VERCEL_GIT_COMMIT_SHA,
	sessionSampleRate: 100,
} as const

const ENV_CONFIG = {
	prod: {
		...SHARED_CONFIG,
		env: 'prod',
	},
	'non-prod': {
		...SHARED_CONFIG,
		sessionReplaySampleRate: 0,
		env: 'non-prod',
	},
} as const

export const getEnv = () => {
	return process.env.HASHI_ENV === 'production' ? 'prod' : 'non-prod'
}

export const getConfig = () => {
	const env = getEnv()
	return ENV_CONFIG[env]
}
