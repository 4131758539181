import classNames from 'classnames'
import s from './style.module.css'

export interface NavColumnProps {
	className?: string
	children: React.ReactNode
	hasSidePanel?: boolean
}

export default function Column({
	className,
	children,
	hasSidePanel,
}: NavColumnProps) {
	return (
		<div
			className={classNames(s.column, className, {
				[s.hasSide]: hasSidePanel,
			})}
		>
			{children}
		</div>
	)
}
