export function toKebabCase(input: string): string {
	// Remove special characters and punctuation
	const inputWithSpecialCharsRemoved = input.replace(/[^\w\s]/gi, '')

	// Convert to kebab case
	const inputWithKebabCase = inputWithSpecialCharsRemoved
		.trim()
		.toLowerCase()
		.replace(/\s+/g, '-')

	return inputWithKebabCase
}

export function toKebabCaseLocalized(input: string): string {
	// TODO: Remove special characters and punctuation but maintain localized characters

	// Convert to kebab case
	const inputWithKebabCase = input.trim().toLowerCase().replace(/\s+/g, '-')

	return inputWithKebabCase
}
