import { datadogLogs } from '@datadog/browser-logs'
import { getEnv, getConfig } from './config'
import { addReactError, reactPlugin } from '@datadog/browser-rum-react'
import { datadogRum } from '@datadog/browser-rum'
import type { InitConfiguration } from '@datadog/browser-core'

/**
 * This is a subset of [datadog's log levels](https://github.com/DataDog/browser-sdk/blob/b7c5509a89739eff8685ef339417f7b12a2d3463/packages/logs/src/domain/logger.ts#L158)
 * For simplicity, we use a subset  but should the need arise we can add more
 */
type LogLevel = 'info' | 'error' | 'warn' | 'alert'

const consoleStyles: Record<LogLevel, string> = {
	info: 'color:white;background:#4a90e2;padding:2px 4px;border-radius:2px;',
	error: 'color:white;background:#e25c5c;padding:2px 4px;border-radius:2px;',
	warn: 'color:white;background:#f5a623;padding:2px 4px;border-radius:2px;',
	alert: 'color:white;background:#f5a623;padding:2px 4px;border-radius:2px;',
} as const

const isBrowser = () => typeof window !== 'undefined'

// mainly 1:1 just with support for alert
const levelToNativeConsoleMethod = {
	info: console.info,
	alert: console.error,
	error: console.error,
	warn: console.warn,
} as const

/**
 * @description  A logging utility that forwards logs to Datadog and the console. This isn't meant to replace the native console methods but rather to augment them in specific scenarios. It's important to note that this is a client side logger and should not be used api routes.
 * @usage
 *
 * 1. Clear Objectives: Mentally define what you're logging and why. Focus on business events, error prone code with external deps (like `fetch` calls) & ux critial hot paths.
 * | Do Log | Don't Log |
 * | ------ | --------- |
 * | ✅ User actions | ❌ Sensitive data (eg Passwords/tokens) |
 * | ✅ Network requests | ❌ Static Routes with no interactivity |
 * | ✅ Critial Paths (eg forms) | ❌ Page Loads |
 * -------------------------
 *
 * 2. Meaningful Context: Include context, for instance if we're logging a form submission, log the form state (but omit sensitive data like passwords)
 *
 * 3. Rather than biasing towards a large amount of logs, prefer a single log with alot of metadata
 *
 * 4. Standardized Messaging.
 *  Try to follow a pattern in the log messages that shows the domain and the event that occurred.
 * For example, if you're logging events in a "cloud maturity quiz" form, you might use the following event names:
 *
 * ```ts
 * const DOMAIN = 'cloud_maturity_quiz'
 * const EVENT = {
 * 	SUBMIT_START: `${DOMAIN}__submit_start`,
 * 	SUBMIT_ERROR: `${DOMAIN}__submit_error`,
 * 	SUBMIT_ATTEMPT: `${DOMAIN}__submit_attempt`,
 * }
 * logger.info(EVENT.SUBMIT_START)
 * ```
 *
 * This makes logs searcahble and easy to filter
 */
class RUMLogger {
	private isInitialized = false

	initialize(initialConsent: InitConfiguration['trackingConsent']): void {
		if (this.isInitialized) return
		if (!isBrowser()) return
		const baseConfig = getConfig()

		datadogLogs.init({
			...baseConfig,
			forwardErrorsToLogs: true,
			trackingConsent: initialConsent,
		})

		datadogRum.init({
			...baseConfig,
			plugins: [reactPlugin()],
			trackingConsent: initialConsent,
			allowedTracingUrls: [
				{
					match: 'https://www.hashicorp.com/api',
					propagatorTypes: ['tracecontext'],
				},
			],
		})

		this.isInitialized = true
		const isProd = baseConfig.env === 'prod'
		if (!isProd) {
			this.logToConsole('info', 'Datadog RUM Logger initialized')
		}
	}

	private logToConsole(
		level: LogLevel,
		message: string,
		context?: Record<string, unknown>,
		error?: Error
	) {
		if (!isBrowser()) return

		const style = consoleStyles[level]
		const method = levelToNativeConsoleMethod[level]
		const hasError = error !== undefined
		if (hasError) {
			if (context) {
				console.group(`%c${level}`, style, message, context)
			} else {
				console.group(`%c${level}`, style, message)
			}
			console.error(error)
			console.groupEnd()
			return
		}

		if (context) {
			method(`%c${level}`, style, message, context)
		} else {
			method(`%c${level}`, style, message)
		}
	}

	info(message: string, context?: Record<string, unknown>): void {
		datadogLogs.logger.info(message, context)
		if (getEnv() !== 'prod') {
			this.logToConsole('info', message, context)
		}
	}

	error(
		error: string,
		context?: Record<string, unknown>,
		errorObject?: Error
	): void {
		datadogLogs.logger.error(error, context, errorObject)

		if (getEnv() !== 'prod') {
			this.logToConsole('error', error, context)
		}
	}

	warn(
		message: string,
		context?: Record<string, unknown>,
		error?: Error
	): void {
		datadogLogs.logger.warn(message, context, error)
		if (getEnv() !== 'prod') {
			this.logToConsole('warn', message, context, error)
		}
	}

	/**
	 * Use with specific intent, the threshold for this to trigger an alert to our slack channel is quite low,
	 * useful for handling "this should never happen" | "this should only happen in freak scenarios" type errors
	 * Ideal for non recoverable errors that a user can't easily get away from, for instance that dreaded White Screen that just says "A clientside error has occurred"
	 */
	alert(
		message: string,
		context?: Record<string, unknown>,
		error?: Error
	): void {
		datadogLogs.logger.alert(message, context, error)
		if (getEnv() !== 'prod') {
			this.logToConsole('alert', message, context, error)
		}
	}

	/**
	 * For usage with React Error Boundaries
	 */
	reactError(error: Error, errorInfo?: React.ErrorInfo) {
		addReactError(error, errorInfo)
	}

	updateTrackingConsent(trackingConsent: InitConfiguration['trackingConsent']) {
		datadogRum.setTrackingConsent(trackingConsent)
		datadogLogs.setTrackingConsent(trackingConsent)
		const isProd = getEnv() === 'prod'
		if (!isProd) {
			this.logToConsole('info', 'Tracking Consent Updated', { trackingConsent })
		}
	}
}

const logger = new RUMLogger()

export { logger }
