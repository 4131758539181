import classNames from 'classnames'
import s from './style.module.css'

interface RootProps {
	className?: string
	children: React.ReactNode
	hasSidePanel?: boolean
}

export default function Root({ className, children, hasSidePanel }: RootProps) {
	return (
		<div
			className={classNames(s.root, className, {
				[s.hasSide]: hasSidePanel,
			})}
		>
			{children}
		</div>
	)
}
