//design-system-components@3.0.2
import classNames from 'classnames'
import Interactive from '../interactive'
import type { ReactNode } from 'react'

export interface InlineLinkProps {
	/**
	 * There are two available colors for an `<InlineLink />`: primary and secondary.
	 */
	color?: 'primary' | 'secondary'
	/**
	 * Use this parameter to show an icon.
	 */
	icon?: ReactNode
	/**
	 * Positions the icon before or after the text.
	 */
	iconPosition?: 'leading' | 'trailing'
	/**
	 * URL parameter that’s passed down to the `<a>` element.
	 */
	href: string
	/**
	 * Controls if the <a> link is external. For security reasons, we add the target="_blank" and rel="noopener noreferrer" attributes to it by default.
	 * default: `false`
	 */
	isHrefExternal?: boolean
	/**
	 * The content of the <a> HTML element.
	 */
	text: string
	/**
	 * Optional class name to add to the component.
	 */
	className?: string
	prefetch?: boolean
	locale?: string
}

function InlineLink({
	text,
	color = 'primary',
	icon,
	iconPosition = 'trailing',
	className,
	...props
}: InlineLinkProps) {
	return (
		<Interactive
			className={classNames(
				'hds-link-inline',
				`hds-link-inline--color-${color}`,
				`hds-link-inline--icon-${iconPosition}`,
				className
			)}
			{...props}
		>
			{icon && iconPosition === 'leading' && (
				<span className="hds-link-inline__icon hds-link-inline__icon--leading">
					{icon}
				</span>
			)}
			{text}
			{icon && iconPosition === 'trailing' && (
				<span className="hds-link-inline__icon hds-link-inline__icon--trailing">
					{icon}
				</span>
			)}
		</Interactive>
	)
}

export default InlineLink
