import React, { useState, useEffect, useCallback, ComponentProps } from 'react'
import classNames from 'classnames'
import { trackGoal as trackFathomGoal } from 'fathom-client'
import { Link } from 'lib/i18n/routing'
import { Button } from '@hashicorp/react-mds/src/components/button'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { HDSPlusStandaloneLink } from '@hashicorp/react-mds/src/hds-plus/standalone-link'
import LogoCorporate from '@hashicorp/web-mktg-logos/src/hashicorp/hashicorp-horizontal_on-light.svg'
import { IconCloud24 } from '@hashicorp/flight-icons/svg-react/cloud-24'
import { IconMail24 } from '@hashicorp/flight-icons/svg-react/mail-24'
import { IconUserCircle24 } from '@hashicorp/flight-icons/svg-react/user-circle-24'
import { IconChevronLeft16 } from '@hashicorp/flight-icons/svg-react/chevron-left-16'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import {
	ProductPanel,
	StandardPanel,
	type NavProductProps,
	type PromoProps,
	type NavItem,
} from '@hashicorp/react-components/src/components/nav-panel'
import type { ProductsPanelProps } from '@hashicorp/react-components/src/components/nav-panel/product-panel'
import { parseUrl } from '@hashicorp/react-components/src/lib/parse-url'

import { default as useMeasure } from 'lib/hooks/use-measure'
import useProductMeta, { type Products } from '@hashicorp/platform-product-meta'
import va from '@vercel/analytics'

import { NavItemDropdown, NavItemLink } from './NavItems'
import Image from '@hashicorp/react-image'
import NextImage from 'next/image'
import fragment from './fragment.graphql'
import sendPosthogEvent from 'lib/posthog/send-posthog-event'

import s from './style.module.css'
import { useNavAnimation } from './use-nav-animation'
import { useLocale } from 'next-intl'

const DEFAULT_CONTACT_US_TEXT = 'Contact us'
const DEFAULT_SIGNUP_TEXT = 'Sign up for free'

/**
 * Pushes a 'Nav link click' custom event to Vercel Analytics.
 * A wrapper around the Vercel Analytics track method.
 *
 * @param {string} name - The name of the nav link
 * @param {string} href - The href for the nav link
 * @param {string} [section] - The name of the section of the nav. Top-level links do not have a section.
 */

export const trackNavClickEvent = (
	name: string,
	href: string,
	section?: string
) => {
	va.track('Nav link click', {
		name,
		href,
		section,
	})
	sendPosthogEvent('Nav link click', {
		name,
		href,
		section,
	})
}

export interface NavProps {
	data: NavDataProps
	product?: Products
	pathname: string | null
	push: (path: string) => void
}

export interface NavDataProps {
	navCategoryData: {
		categories: NavItem[] | NavProductProps[]
		title: string
		promo: PromoProps | null
		sidePanel: ProductsPanelProps['sidePanel'] | null
		categoryType: string
	}[]
	contactUsCtaText?: string
	signUpCtaText?: string
}

function Nav({
	data: {
		navCategoryData,
		contactUsCtaText = DEFAULT_CONTACT_US_TEXT,
		signUpCtaText = DEFAULT_SIGNUP_TEXT,
	},
	product,
	pathname,
	push,
}: NavProps) {
	const locale = useLocale()
	const [activeDesktop, setActiveDesktop] = useState(false)
	const [activeMobile, setActiveMobile] = useState(false)
	const [activePanel, setActivePanel] = useState(null)
	const { setListRef, offset, handleTriggerRef } = useNavAnimation(activePanel)

	// close all panels
	const closePanels = useCallback(() => {
		setActiveDesktop(false)
		setActiveMobile(false)
		setActivePanel(null)
		document.body.classList.remove('g-noscroll')
	}, [])

	// activate a panel
	const activatePanel = (panel) => {
		if (activePanel === panel || panel === '') {
			closePanels()
		} else {
			setActiveDesktop(!activeMobile)
			setActivePanel(panel)
		}
	}

	// activate mobile version
	const activateMobile = () => {
		if (activePanel) {
			closePanels()
		} else {
			document.body.classList.add('g-noscroll')
			setActiveDesktop(false)
			setActiveMobile(true)
			setActivePanel('mobile')
		}
	}

	// Getting `themeClass` allows us to render product-specific background colors
	// on the secondary CTA button.
	const { themeClass } = useProductMeta(product)

	// Clear state and `.g-noscroll` when changing from mobile/desktop.
	// We're just clearing state rather than trying to translate states
	// between mobile and desktop. This was also a request from design.
	const handleResize = useCallback(() => {
		window.matchMedia('(min-width: 1120px)').matches
			? activeMobile && closePanels()
			: activeDesktop && closePanels()
	}, [activeMobile, activeDesktop, closePanels])

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [handleResize])

	// after route change close all panels
	useEffect(() => {
		closePanels()

		// Close all panels when nav unmounts
		// This ensures that `g-noscroll` does not persist
		// when navigating to a page that does not render
		// the global nav
		return () => {
			closePanels()
		}
	}, [pathname])

	// Redirect to the branding page when someone right-clicks on the
	// HashiCorp logo
	const logoOnContextMenu = (e) => {
		e.preventDefault()
		push('/brand')
	}

	// This logic determines both whether or not to render the sign up CTA
	// and what `href` to use. We only render the signup CTA on product pages,
	// the homepage and the cloud page.
	// https://app.asana.com/0/1202791227659279/1203564965127094/f
	const isNomadPage = pathname && pathname.includes('/products/nomad')
	const isTerraformPage = pathname && pathname.includes('/products/terraform')
	const isCloudSignupPage =
		pathname &&
		(pathname === '/' ||
			pathname.includes('/products') ||
			pathname === '/cloud')

	let signUpCtaHref: string | null = null
	const signUpCtaTitle = isNomadPage ? 'Try Nomad free' : signUpCtaText
	if (isNomadPage) {
		signUpCtaHref =
			'https://developer.hashicorp.com/nomad/tutorials/enterprise/hashicorp-enterprise-license'
	} else if (isTerraformPage) {
		signUpCtaHref = 'https://app.terraform.io/public/signup/account'
	} else if (isCloudSignupPage) {
		signUpCtaHref = 'https://portal.cloud.hashicorp.com/sign-up'
	}

	const logInCta: ComponentProps<typeof HDSPlusStandaloneLink> = {
		text: 'Log in',
		href: 'https://portal.cloud.hashicorp.com/sign-in',
		icon: (
			<IconUserCircle24
				style={{ display: 'block' }}
				width="100%"
				height="100%"
			/>
		),
		iconPosition: 'leading',
		onClick: () => {
			trackNavClickEvent('Log in', 'https://portal.cloud.hashicorp.com/sign-in')
		},
		size: 'medium',
	}

	const contactSalesCta: ComponentProps<typeof Button> = {
		title: contactUsCtaText,
		url: 'https://www.hashicorp.com/contact-sales',
		icon: (
			<IconMail24 style={{ display: 'block' }} width="100%" height="100%" />
		),
		iconPosition: 'leading',
		onClick: () => {
			if (window && window.analytics) {
				window.analytics.track('CTA Clicked', {
					name: 'Navigation Get Pricing',
					variant: 'Contact Sales',
				})
			}
			trackFathomGoal('SINRLCST', 0)
			trackNavClickEvent(
				'Contact sales',
				'https://www.hashicorp.com/contact-sales'
			)
		},
		theme: {
			variant: 'secondary',
		},
		size: 'small',
	}

	const signUpCta: ComponentProps<typeof Button> = {
		title: signUpCtaTitle,
		url: signUpCtaHref,
		icon: (
			<IconCloud24 style={{ display: 'block' }} width="100%" height="100%" />
		),
		iconPosition: 'leading',
		onClick: () => {
			trackFathomGoal('PI1WFPA6', 0)
			trackNavClickEvent(signUpCtaTitle, signUpCtaHref)
		},
		size: 'small',
	}

	const [mobileCtasInnerRef, { height }] = useMeasure()
	const panelBottomPosition = height ? `${height - 1}px` : '151px'
	const headerStyle = {
		'--panelBottomPosition': panelBottomPosition,
	} as React.CSSProperties

	const isValidOffset = typeof offset === 'number'
	const viewPortStyle = {
		// Avoids transitioning from last position when opening
		display: isValidOffset ? undefined : 'none',
		'--hashi-navigation-menu-translate': isValidOffset ? `${offset}px` : '0',
	} as React.CSSProperties

	const isActive = activeDesktop || activeMobile

	return (
		<header
			className={classNames([s.nav, themeClass && themeClass])}
			style={headerStyle}
		>
			<NavigationMenu.Root
				onValueChange={activatePanel}
				value={activePanel}
				delayDuration={0}
			>
				<div className={classNames([s.navInner, isActive && s.isActive])}>
					{/* Logos */}
					<div
						className={classNames([
							s.navBarToggle,
							activeMobile && activePanel !== 'mobile' && s.isBack,
						])}
					>
						<NavigationMenu.Link asChild>
							<Link
								href="/"
								onContextMenu={logoOnContextMenu}
								title="HashiCorp"
							>
								<div className={s.logo}>
									<NextImage src={LogoCorporate} alt="HashiCorp logo" />
								</div>
							</Link>
						</NavigationMenu.Link>
						<NavigationMenu.Link asChild>
							<HDSPlusStandaloneLink
								text="Back"
								icon={<IconChevronLeft16 />}
								className={s.breadcrumb}
								onClick={() => activatePanel('mobile')}
								iconPosition="leading"
								size="large"
							/>
						</NavigationMenu.Link>
					</div>
					{/* Mobile CTAs */}
					<div className={s.ctasMobile}>
						{isActive ? null : (
							<NavigationMenu.List className={s.ctasWrapper}>
								<NavigationMenu.Item className={s.ctaMobilePrimary}>
									<NavigationMenu.Link className={s.cta} asChild>
										<HDSPlusStandaloneLink {...logInCta} />
									</NavigationMenu.Link>
								</NavigationMenu.Item>
								<NavigationMenu.Item className={s.ctaMobilePrimary}>
									<NavigationMenu.Link className={s.cta} asChild>
										<Button {...contactSalesCta} />
									</NavigationMenu.Link>
								</NavigationMenu.Item>
								{signUpCtaHref ? (
									<NavigationMenu.Item className={s.ctaMobileSecondary}>
										<NavigationMenu.Link className={s.cta} asChild>
											<Button {...signUpCta} data-testid="signupCtaMobile" />
										</NavigationMenu.Link>
									</NavigationMenu.Item>
								) : null}
							</NavigationMenu.List>
						)}
						<div className={s.mobileToggleContainer}>
							<NavigationMenu.Link asChild>
								<button
									type="button"
									className={classNames([
										s.mobileToggle,
										isActive && s.isActive,
									])}
									onClick={activateMobile}
									aria-label={activeMobile ? 'Close Menu' : 'Open Menu'}
									data-testid="mobileToggle"
								>
									<FlightIcon name="menu" size={24} />
									<FlightIcon name="x" size={24} />
								</button>
							</NavigationMenu.Link>
						</div>
					</div>
					{/* Links */}
					<div
						className={classNames([s.links, isActive && s.isActive])}
						data-testid="navLinks"
					>
						<NavigationMenu.List className={s.linksList} ref={setListRef}>
							{navCategoryData.map((category) => {
								// If the category type is 'Single Link' render a link instead of a dropdown using the first item in the navItems array
								if (
									category.categoryType === 'Single Link' &&
									'navItems' in category.categories[0]
								) {
									const { url } = category.categories[0].navItems[0]
									return (
										<NavItemLink
											key={category.title}
											href={parseUrl(url).href}
											text={category.title}
											mobileActive={activePanel === 'mobile'}
										/>
									)
								}

								return (
									<NavItemDropdown
										key={category.title}
										active={activePanel === category.title.toLowerCase()}
										handleActivate={activatePanel}
										title={category.title}
										mobileActive={activePanel === 'mobile'}
										handleTriggerRef={handleTriggerRef}
									>
										{category.categoryType === 'Product' ? (
											<ProductPanel
												locale={locale}
												productCategories={category.categories}
												promo={category.promo}
												sidePanel={category.sidePanel}
											/>
										) : (
											<StandardPanel
												locale={locale}
												navData={category.categories as NavItem[]}
												promo={category.promo}
												sectionName={category.title}
											/>
										)}
									</NavItemDropdown>
								)
							})}
						</NavigationMenu.List>
						<div className={s.viewportPos}>
							<NavigationMenu.Viewport
								className={s.viewport}
								style={viewPortStyle}
							/>
						</div>
					</div>
					{/* Mobile CTAs (inner) */}
					<div
						className={classNames([
							s.ctasMobileInnerWrapper,
							isActive &&
								activeMobile &&
								activePanel === 'mobile' &&
								s.isActive,
						])}
						ref={mobileCtasInnerRef}
					>
						<NavigationMenu.List className={s.ctasMobileInner}>
							<NavigationMenu.Item className={s.ctaMobileInner}>
								<div className={s.cta}>
									<NavigationMenu.Link asChild>
										<HDSPlusStandaloneLink {...logInCta} />
									</NavigationMenu.Link>
								</div>
							</NavigationMenu.Item>
							<NavigationMenu.Item
								className={classNames([
									s.ctaMobileInner,
									s.ctaMobileInnerPrimary,
								])}
							>
								<div className={s.cta}>
									<NavigationMenu.Link asChild>
										<Button {...contactSalesCta} />
									</NavigationMenu.Link>
								</div>
							</NavigationMenu.Item>

							{signUpCtaHref ? (
								<NavigationMenu.Item className={s.ctaMobileInner}>
									<div className={s.cta}>
										<NavigationMenu.Link asChild>
											<Button
												{...signUpCta}
												data-testid="signupCtaMobileInner"
											/>
										</NavigationMenu.Link>
									</div>
								</NavigationMenu.Item>
							) : null}
						</NavigationMenu.List>
					</div>
					{/* Desktop CTAs */}
					<div className={s.ctasDesktop}>
						<NavigationMenu.List className={s.ctasWrapper}>
							<NavigationMenu.Item className={s.cta}>
								<NavigationMenu.Link asChild>
									<HDSPlusStandaloneLink {...logInCta} />
								</NavigationMenu.Link>
							</NavigationMenu.Item>
							<NavigationMenu.Item className={s.cta}>
								<NavigationMenu.Link asChild>
									<Button {...contactSalesCta} />
								</NavigationMenu.Link>
							</NavigationMenu.Item>
							{signUpCtaHref ? (
								<NavigationMenu.Item className={s.cta}>
									<NavigationMenu.Link asChild>
										<Button {...signUpCta} data-testid="signupCta" />
									</NavigationMenu.Link>
								</NavigationMenu.Item>
							) : null}
						</NavigationMenu.List>
					</div>
				</div>
			</NavigationMenu.Root>
		</header>
	)
}

Nav.fragmentSpec = { fragment, dependencies: [Image] }

export default Nav
