// Page percentage thresholds to track
const scrollThresholds: number[] = [25, 50, 75, 90]

// Analytics event that fires after threshold is met
type AnalyticsEvent = (percent: number) => void
const event: AnalyticsEvent = (percent) => {
	if (window.analytics) {
		window.analytics.track('Page Scrolled', { percent })
	}
}

// Frequency at which our handler fires (in ms)
const tickInterval = 800

export function start(): number {
	// We don't want to mutate the original source so we'll create a copy
	const percentages = [...scrollThresholds]

	const clientHeight: number =
		document.documentElement.clientHeight || window.innerHeight || 0
	const documentHeight: number = document.documentElement.scrollHeight

	// Setup a timer to fire indefinitely at a specified interval
	const intervalId: number = window.setInterval(function fireHandler() {
		window.requestAnimationFrame(timerHandler)
	}, tickInterval)

	let furthestPercentageScrolled = 0

	function timerHandler() {
		// Once all thresholds have been surpassed (indicated by no more items in array),
		// our work is done and we can remove the timer
		if (percentages.length === 0) {
			stop(intervalId)
			return
		}

		const scrollPosition: number =
			(window.pageYOffset || document.documentElement.scrollTop || 0) +
			clientHeight
		const percentageScrolled: number = Math.round(
			(scrollPosition / documentHeight) * 100
		)

		// We can short-circuit out of this function if we haven't scrolled down the page
		if (percentageScrolled <= furthestPercentageScrolled) return

		furthestPercentageScrolled = percentageScrolled

		// Check if our scroll progress has exceeded the first percentage in the array.
		while (percentageScrolled >= percentages[0]) {
			// Mutate array by "shifting" off the first element and capture its value
			const surpassedThreshold: number = percentages.shift()
			window.requestAnimationFrame(() => event(surpassedThreshold))
		}
	}

	return intervalId
}

export function stop(intervalId: number): void {
	window.clearInterval(intervalId)
}

export function reset(intervalId: number): number {
	stop(intervalId)
	return start()
}
