import va from '@vercel/analytics'

/**
 * Pushes a 'Nav link click' custom event to Vercel Analytics.
 * A wrapper around the Vercel Analytics track method.
 *
 * @param {string} name - The name of the nav link
 * @param {string} href - The href for the nav link
 * @param {string} [section] - The name of the section of the nav. Top-level links do not have a section.
 */

export const trackNavClickEvent = (
	name: string,
	href: string,
	section: string
) => {
	va.track('Nav link click', {
		name,
		href,
		section,
	})
}
