import { vercelStegaSplit } from '@vercel/stega'

/**
 * Takes in a Vercel Visual Editing string and returns the string without
 * hidden data.
 *
 */
export const vercelSplit = <VercelString>(
	original: VercelString
): VercelString => vercelStegaSplit(original as string).cleaned as VercelString
