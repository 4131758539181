//design-system-components@4.4.1

import classNames from 'classnames'
import { useId } from 'react'
import Badge, { BadgeProps } from '../../badge'
import FlightIcon from '../../flight-icon'
import { RadioBase } from '../radio'
import RadioCardGroup from './group'
import Label from './label'
import Description from './description'
import type { HTMLProps, ReactNode } from 'react'

export const DEFAULT_CONTROL_POSITION = 'bottom'
export const DEFAULT_ALIGNMENT = 'left'
export const CONTROL_POSITIONS = ['bottom', 'left'] as const
export const ALIGNMENTS = ['left', 'center'] as const

export interface RadioCardProps {
	/**
	 * Sets the position of the form control in relation to the Radio Card content.
	 */
	controlPosition?: (typeof CONTROL_POSITIONS)[number]
	/**
	 * Sets the alignment of the Radio Card content.
	 */
	alignment?: (typeof ALIGNMENTS)[number]
	/**
	 * any valid CSS width (%, vw, etc)
	 * This parameter will set the width of the card, wrapping cards on multiple rows if necessary.
	 * You can use it to define the number of Radio Cards shown per row (for example `25%`
	 * will result in 4 cards).
	 */
	maxWidth?: `${number}px` | `${number}vw` | `${number}%`
	/**
	 * Renders <Badge /> component.
	 */
	badges?: Array<BadgeProps>
	/**
	 * The name of the flight icon to render.
	 */
	icon?: string
	/**
	 * The label text.
	 */
	label?: string
	/**
	 * The description text.
	 */
	description?: string
	/*
	 * Optional children rendered below the label and description (if provided).
	 */
	children?: ReactNode
	field: HTMLProps<HTMLInputElement>
	id?: string
}

function RadioCard({
	controlPosition = DEFAULT_CONTROL_POSITION,
	alignment = DEFAULT_ALIGNMENT,
	maxWidth,
	field,
	icon,
	label,
	description,
	badges,
	children,
}: RadioCardProps) {
	const generatedId = useId()

	return (
		<label
			htmlFor={generatedId}
			style={{ maxWidth }}
			className={classNames(
				'hds-form-radio-card',
				`hds-form-radio-card--control-${controlPosition}`,
				`hds-form-radio-card--align-${alignment}`,
				{
					['hds-form-radio-card--checked']: field.checked,
					['hds-form-radio-card--disabled']: field.disabled,
					['hds-form-radio-card--has-fixed-width']: maxWidth,
					['hds-form-radio-card--has-fluid-width']: !maxWidth,
				}
			)}
		>
			<span className="hds-form-radio-card__content">
				{icon && <FlightIcon name={icon} size={24} isInlineBlock={false} />}
				{label && <Label label={label} />}
				{badges?.length
					? badges.map((badge) => (
							<>
								<Badge {...badge} key={badge.text} />{' '}
							</>
					  ))
					: null}
				{description && <Description description={description} />}
				{children}
			</span>
			<span className="hds-form-radio-card__control-wrapper">
				<RadioBase
					className="hds-form-radio-card__control"
					id={generatedId}
					field={field}
				/>
			</span>
		</label>
	)
}

export default RadioCard
export { RadioCardGroup }
