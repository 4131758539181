'use client'

import React, { useRef, useEffect, type ReactNode } from 'react'
import { trackGoal as trackFathomGoal } from 'fathom-client'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import {
	initializeUTMParamsCapture,
	addGlobalLinkHandler,
	track,
} from '@hashicorp/platform-analytics'
import * as documentScrollTracker from 'lib/documentScrollTracker'
import useFathomAnalytics from 'lib/hooks/use-page-view-analytics'
import { Notifications } from 'components/notification'
import sendPosthogEvent from 'lib/posthog/send-posthog-event'
import { useLocale } from 'next-intl'
import { logger } from 'lib/datadog/rum-logger'
import {
	addDatagrailEventListener,
	removeDatagrailEventListeners,
} from '@web/consent-manager'
import type { DatagrailPreferencesPayload } from '@web/consent-manager/src/types'

export function useDocumentScrollTimer(route: string) {
	const timerId = useRef<number | null>(null)

	useEffect(() => {
		// Initialize and track timer; stop/reset accordingly on route changes
		// Hook into Next.js Router event to reset timer on client-side routing changes
		timerId.current = timerId.current
			? documentScrollTracker.reset(timerId.current)
			: documentScrollTracker.start()

		return () => {
			if (timerId) {
				documentScrollTracker.stop(timerId.current)
			}
		}
	}, [route])
}

export function useQualified(route: string) {
	useEffect(() => {
		if (
			'qualified' in globalThis &&
			qualified &&
			typeof qualified !== 'undefined' &&
			typeof qualified === 'function'
		) {
			qualified('page')
		}
	}, [route])

	return null
}

export function useUTMParamsCapture() {
	useEffect(() => {
		initializeUTMParamsCapture()
	}, [])

	return null
}

export function useGlobalLinkHandler() {
	useEffect(() => {
		addGlobalLinkHandler((destinationUrl) => {
			track('Outbound link', {
				destination_url: destinationUrl,
			})
			sendPosthogEvent('Outbound link', {
				destination_url: destinationUrl,
			})
		})
	}, [])

	return null
}

const useAxeAccessibilityLogs = () => {
	const isOnClient = typeof window !== 'undefined'
	const isNotInProd = process.env.HASHI_ENV !== 'production'
	const shouldUseAxe = isOnClient && isNotInProd

	if (!shouldUseAxe) {
		return
	}

	Promise.allSettled([
		import('react-dom/server'),
		import('@axe-core/react'),
	]).then(([reactDomRes, axeReactMod]) => {
		const bothSuccess =
			reactDomRes.status === 'fulfilled' && axeReactMod.status === 'fulfilled'
		if (!bothSuccess) {
			console.error('Failed to mount axe-core/react dev tools')
			reactDomRes.status === 'rejected' && console.error(reactDomRes.reason)
			axeReactMod.status === 'rejected' && console.error(axeReactMod.reason)
			return
		}
		const ReactDOM = reactDomRes.value
		const axeMod = axeReactMod.value
		const axe = axeMod.default
		axe(React, ReactDOM, 1000)
	})
}

const useInitDDRumLogger = () => {
	const findPerfCookie = (prefs: DatagrailPreferencesPayload) => {
		return prefs.consentPreferences.cookieOptions.find(
			(o) => o.gtm_key === 'dg-category-performance'
		)
	}

	const handlePrefs = (preferences: DatagrailPreferencesPayload) => {
		const performanceConsent = findPerfCookie(preferences)

		// Array.prototype.find returns undefined if nothing is found, https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
		// this should not happen since we know what datagrail exposes to us ahead of time, but in abundance of caution we check & handle it
		const isNothingFound = !performanceConsent
		if (isNothingFound) {
			logger.updateTrackingConsent('not-granted')
			return
		}

		const trackingConsent = performanceConsent.isEnabled
			? 'granted'
			: 'not-granted'
		logger.updateTrackingConsent(trackingConsent)
	}

	const handleInitPrefs = (preferences: DatagrailPreferencesPayload) => {
		const performanceConsent = findPerfCookie(preferences)

		// Array.prototype.find returns undefined if nothing is found, https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
		// this should not happen since we know what datagrail exposes to us ahead of time, but in abundance of caution we check & handle it
		const isNothingFound = !performanceConsent
		if (isNothingFound) {
			logger.initialize('not-granted')
			return
		}

		const trackingConsent = performanceConsent.isEnabled
			? 'granted'
			: 'not-granted'
		logger.initialize(trackingConsent)
	}

	useEffect(() => {
		addDatagrailEventListener('initial_preference_callback', handleInitPrefs)
		addDatagrailEventListener('preference_callback', handlePrefs)

		return removeDatagrailEventListeners
	}, [])
}

interface Props {
	pathname: string
	children: ReactNode
}

export default function AppGlobals({ pathname, children }: Props) {
	const locale = useLocale()

	useDocumentScrollTimer(pathname)

	useQualified(pathname)

	useFathomAnalytics(pathname)

	useGlobalLinkHandler()

	useUTMParamsCapture()

	useAxeAccessibilityLogs()

	useInitDDRumLogger()

	return (
		<>
			{children}
			{/*
                Returning `null` for pageview events prevents pageviews from
                being included in analytics events and thus ensures we don't exceed
                our event limits. This should be removed if we decide to leverage
                Vercel Analytics for pageview tracking.
            */}
			<Analytics
				beforeSend={(event) => {
					if (event.type === 'pageview') {
						return null
					}
					return event
				}}
			/>
			<SpeedInsights sampleRate={0.2} />
			<Notifications />
		</>
	)
}
