import classNames from 'classnames'
import Badge from '../../badge'

interface IndicatorProps {
	isOptional?: boolean
	isRequired?: boolean
}

export default function Indicator({ isOptional, isRequired }: IndicatorProps) {
	const className = classNames('hds-form-indicator', {
		['hds-form-indicator--optional']: isOptional,
		['hds-typography-body-100']: isOptional,
		['hds-font-weight-regular']: isOptional,
	})

	if (isOptional) {
		return (
			<>
				&nbsp;
				<span aria-hidden="true" className={className}>
					(Optional)
				</span>
			</>
		)
	} else if (isRequired) {
		return (
			<>
				&nbsp;
				<Badge
					aria-hidden="true"
					className={className}
					size="small"
					color="neutral"
					text="Required"
				/>
			</>
		)
	}

	return null
}
