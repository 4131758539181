import React, { type ReactNode } from 'react'
import type { Products } from '@hashicorp/platform-product-meta'
import type { cmsQuery } from '@web/cms'
import GlobalHeadTags from 'components/GlobalHeadTags'
import { StandardLayoutQueryDocument } from 'types/dato'
import Footer from 'components/materia/footer'
import Nav from './nav'
import type { StandardLayoutQueryQuery } from 'types/dato'

export interface Props {
	children: ReactNode
	product?: Products
	locale: string
	data: StandardLayoutQueryQuery
}

export default function StandardLayout({
	data,
	product,
	locale,
	children,
}: Props) {
	/**
	 * Due to Next.js optimizing routing between paths that have a shared
	 * segment, subnavigation at a segment that should go from a Page
	 * Router page to an App Router page results in data being undefined and
	 * an error being thrown. By reloading the page we bypass Next.js route
	 * optimization and force "hard navigating" to the desired App Router page.
	 */
	if (!data) {
		location.reload()
		return null
	}

	return (
		<>
			<div id="modal-portal" />
			<GlobalHeadTags data={data._site} />
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<Nav data={data.nav} product={product} />
			<main id="main">{children}</main>
			<Footer {...data.footer} locale={locale} />
		</>
	)
}

StandardLayout.cmsQueryParams = [
	{ query: StandardLayoutQueryDocument },
] as Parameters<typeof cmsQuery>
