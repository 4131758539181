import React from 'react'
import { Link } from 'lib/i18n/routing'
import { IconChevronRight24 } from '@hashicorp/flight-icons/svg-react/chevron-right-24'
import { trackNavClickEvent } from '@hashicorp/react-components/src/lib/track-nav-click-event'
import classNames from 'classnames'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { useId } from '@radix-ui/react-id'
import { toKebabCaseLocalized } from 'lib/to-kebab-case'
import s from './style.module.css'

interface NavItemDropdownProps {
	active: boolean
	handleActivate: (panelId: string) => void
	children: React.ReactNode
	title: string
	mobileActive: boolean
	handleTriggerRef: (ref: HTMLButtonElement, radixId: string) => void
}

export const NavItemDropdown = ({
	active,
	handleActivate,
	children,
	title,
	mobileActive,
	handleTriggerRef,
}: NavItemDropdownProps) => {
	const panelId = toKebabCaseLocalized(title)
	const id = useId()

	const handleFocusOutside = () => {
		// Since the content opens when the focus is on the dropdown trigger, handleFocusOutside gets called
		// because the content is open but focus is outside of it. When the focus is on the trigger we want
		// this content to be open so we ignore it in this case.
		if (
			!document ||
			!document.activeElement?.id ||
			!document.activeElement.id.includes('trigger')
		) {
			handleActivate('')
		}
	}

	const onFocus = () => {
		handleActivate(id)
	}

	return (
		<NavigationMenu.Item
			className={classNames([
				s.navItem,
				panelId,
				active && s.active,
				mobileActive && s.mobileActive,
			])}
			data-testid={`navItem-${panelId}`}
			value={id}
		>
			{/* Desktop */}
			<NavigationMenu.Trigger
				className={s.navItemTrigger}
				onFocus={onFocus}
				onMouseDown={(e) => e.preventDefault()}
				ref={(node) => handleTriggerRef(node, id)}
			>
				{title}
			</NavigationMenu.Trigger>
			<NavigationMenu.Content
				className={s.panel}
				data-testid={`panel-${panelId}`}
				onFocusOutside={handleFocusOutside}
			>
				<div className={s.panelInner}>
					<div className={s.panelContent}>{children}</div>
				</div>
			</NavigationMenu.Content>
			{/* Mobile */}
			<button
				type="button"
				className={s.mobileNavItemTrigger}
				onClick={() => handleActivate(panelId)}
			>
				{title}
				<IconChevronRight24 />
			</button>
			<div className={s.mobilePanel} data-testid={`mobile-panel-${panelId}`}>
				<div className={s.panelInner}>
					<div className={s.panelContent}>{children}</div>
				</div>
			</div>
		</NavigationMenu.Item>
	)
}

interface NavItemLinkProps {
	href: string
	text: string
	mobileActive: boolean
}

export const NavItemLink = ({ href, text, mobileActive }: NavItemLinkProps) => {
	return (
		<li className={classNames([s.navItem, mobileActive && s.mobileActive])}>
			<Link
				href={href}
				className={classNames(s.navItemTrigger, s.navItemLink)}
				onClickCapture={() => {
					trackNavClickEvent(text, href, text)
				}}
			>
				{text}
			</Link>
		</li>
	)
}
