import { usePathname, useRouter } from 'lib/i18n/routing'
import Nav, { type NavProps } from './component'

export default function PagesRouterNav(
	props: Omit<NavProps, 'pathname' | 'push'>
) {
	const pathname = usePathname()
	const router = useRouter()

	return (
		<Nav
			{...props}
			data={{
				...props.data,
				signUpCtaText: props.data.signUpCtaText || undefined,
				contactUsCtaText: props.data.contactUsCtaText || undefined,
			}}
			pathname={pathname}
			push={router.push}
		/>
	)
}
