import localFont from 'next/font/local'
import s from './style.module.css'

const hashicorpSans = localFont({
	src: './HashiCorp_Sans_Variable.woff2',
	display: 'swap',
	variable: '--hashicorp-sans',
	weight: '300 600',
})

export const cssVariableOverrideClass = s.hashicorpSans

export default hashicorpSans
