import classNames from 'classnames'
import type { ComponentPropsWithRef, ElementType, ReactNode } from 'react'

const AVAILABLE_COLORS = [
	'primary',
	'strong',
	'faint',
	'disabled',
	'high-contrast',
	'action',
	'action-hover',
	'action-active',
	'highlight',
	'highlight-on-surface',
	'highlight-high-contrast',
	'success',
	'success-on-surface',
	'success-high-contrast',
	'warning',
	'warning-on-surface',
	'warning-high-contrast',
	'critical',
	'critical-on-surface',
	'critical-high-contrast',
] as const

// small typescript hack to support autocomplete and arbitrary strings
type HexColor = string & { hexish?: any }

export interface BaseTextProps<T extends ElementType> {
	tag?: T
	size?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800'
	weight?: 'regular' | 'medium' | 'semibold' | 'bold'
	align?: 'left' | 'center' | 'right'
	color?: (typeof AVAILABLE_COLORS)[number] | HexColor
	children: ReactNode
	className?: string
}

interface TextProps<T extends ElementType> extends BaseTextProps<T> {
	group:
		| 'body'
		| 'code'
		| 'display'
		| 'hdsplus-body'
		| 'hdsplus-display-expressive'
		| 'hdsplus-label'
}

export function Text<T extends ElementType = 'span'>({
	tag,
	group,
	size = '200',
	weight,
	align,
	color,
	children,
	className,
	...rest
}: TextProps<T> & Omit<ComponentPropsWithRef<T>, keyof TextProps<T>>) {
	const Component = tag ?? 'span'
	const variant = group.includes('hdsplus-')
		? group === 'hdsplus-label'
			? 'hdsplus-typography-label'
			: `hdsplus-typography-${group.split('hdsplus-')[1]}-${size}`
		: `hds-typography-${group}-${size}`

	const usesPredefinedColor = AVAILABLE_COLORS.includes(
		color as (typeof AVAILABLE_COLORS)[number]
	)

	return (
		<Component
			className={classNames(
				'hds-text',
				variant,
				{
					[`hds-font-weight-${weight}`]: weight,
					[`hds-text--align-${align}`]: align,
					[`hds-foreground-${color}`]: color && usesPredefinedColor,
				},
				className
			)}
			style={color && !usesPredefinedColor ? { color } : {}}
			{...rest}
		>
			{children}
		</Component>
	)
}
