import classNames from 'classnames'
import { ReactNode } from 'react'
import Label from '../label'
import HelperText from '../helper-text'
import Error from '../error'

export interface FieldProps {
	id: string
	isRequired?: boolean
	isOptional?: boolean
	label?: ReactNode
	helperText?: ReactNode
	error?: ReactNode
	className?: string
	layout?: 'vertical' | 'flag'
	children: ReactNode
}

export default function Field({
	id,
	isRequired,
	isOptional,
	label,
	helperText,
	error,
	className,
	layout,
	children,
	...rest
}: FieldProps) {
	return (
		<div
			className={classNames(
				{ [`hds-form-field--layout-${layout}`]: layout },
				className
			)}
			{...rest}
		>
			{label && (
				<Label
					controlId={id}
					isOptional={isOptional}
					isRequired={isRequired}
					className="hds-form-field__label"
				>
					{label}
				</Label>
			)}
			{helperText && <HelperText controlId={id}>{helperText}</HelperText>}
			<div className="hds-form-field__control">{children}</div>
			{error && (
				<Error controlId={id} className="hds-form-field__error">
					{error}
				</Error>
			)}
		</div>
	)
}
