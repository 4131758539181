import { useFormContext } from 'react-hook-form'
import RadioCard, {
	RadioCardGroup,
} from '@hashicorp/react-hds/src/components/form/radio-card'
import FieldWrapper from '../../field-wrapper'
import type { MarketoFormRadioButtonsField } from '../../../types'

const RadioCardField = ({ field }: { field: MarketoFormRadioButtonsField }) => {
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext()
	const error = errors[field.id]?.message as string
	const currentSelection = watch(field.id)

	return (
		<FieldWrapper fieldId={field.id}>
			<RadioCardGroup
				error={error}
				layout="vertical"
				isRequired={field.required}
				legend={field.label}
				id={field.id}
				aria-describedby={error && `error-${field.id}-error`}
			>
				{field.fieldMetaData.values.map(({ label, value }) => (
					<RadioCard
						controlPosition="left"
						key={value}
						label={label}
						field={{
							...register(field.id, { required: field.validationMessage }),
							checked: currentSelection === value,
							value,
							name: field.id,
							required: field.required,
							'aria-invalid': Boolean(errors[field.id]),
							'aria-describedby': error && `error-${field.id}-error`,
						}}
					/>
				))}
			</RadioCardGroup>
		</FieldWrapper>
	)
}

export default RadioCardField
