import classNames from 'classnames'
import Link from 'next/link'
import * as NavPanel from '../components/panel'
import Promo, { type PromoProps } from '../components/promo'
import { getLocalizedLinkProps } from '../../../lib/get-localized-link-props'
import { Products } from '@hashicorp/platform-product-meta'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import { TextPlus } from '@hashicorp/react-hds/src/components/text'
import { trackNavClickEvent } from '../../../lib/track-nav-click-event'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import s from './style.module.css'

const PRODUCT_LOGO_MAP = new Map([
	['Terraform', 'terraform-fill'],
	['Packer', 'packer-fill-color'],
	['Vault', 'vault-fill-color'],
	['Boundary', 'boundary-fill-color'],
	['Consul', 'consul-fill-color'],
	['Nomad', 'nomad-fill-color'],
	['Waypoint', 'waypoint-fill-color'],
	['Vagrant', 'vagrant-fill-color'],
	['HCP Vault Secrets', 'vault-secrets'],
	['HCP Vault Radar', 'vault-radar'],
])

const PRODUCT_LOGO_EXCEPTION_CLASSNAMES = {
	Terraform: 'terraform',
	'HCP Vault Secrets': 'hcpVaultSecrets',
	'HCP Vault Radar': 'hcpVaultRadar',
}

export interface ProductsPanelProps {
	locale: string
	productCategories: any
	promo?: PromoProps
	sidePanel?: {
		label: string
		navItems: {
			icon: string
			title: string
			description: string
			url: string
		}[]
	}
}

const ProductPanel = ({
	locale,
	productCategories,
	promo,
	sidePanel,
}: ProductsPanelProps) => {
	return (
		<NavPanel.Root
			className={classNames(s.productPanel, {
				[s.hasSide]: sidePanel,
				[s.noSide]: !sidePanel,
			})}
			hasSidePanel={Boolean(sidePanel)}
		>
			<div className={s.mainPanel}>
				<NavPanel.Column hasSidePanel={Boolean(sidePanel)}>
					<ul className={s.productCategories}>
						{productCategories.map(({ title, products }) => {
							return (
								<li key={title} className={s.productCategory}>
									<TextPlus.Body className={s.eyebrow}>{title}</TextPlus.Body>
									<ul className={s.productList}>
										{products.map((product) => {
											return (
												<li key={product.url}>
													<NavProduct locale={locale} {...product} />
												</li>
											)
										})}
									</ul>
								</li>
							)
						})}
					</ul>
				</NavPanel.Column>
				{promo ? <Promo {...promo} hasSidePanel={Boolean(sidePanel)} /> : null}
			</div>

			{sidePanel ? (
				<NavPanel.Column className={s.sidePanel}>
					<TextPlus.Body size="200" weight="semibold">
						{sidePanel.label}
					</TextPlus.Body>

					{/* ! TODO Replace the below list with the <LinkList /> component, 
						That component will require a refactor to support greater customization 
						Ticket:
						https://app.asana.com/0/1206176289707208/1208162145034615/f
						*/}
					<ul className={s.sidePanelItems}>
						{sidePanel.navItems.map(({ icon, title, description, url }) => {
							return (
								<li key={title}>
									<Link
										aria-label={`${title} - ${description}`}
										{...getLocalizedLinkProps(url, locale)}
										locale={locale ? locale : undefined}
										className={classNames(s.sidePanelItem, s.focusIndicator)}
									>
										<div className={s.iconBackground}>
											<FlightIcon
												size={16}
												name={icon}
												color="var(--token-color-foreground-faint)"
											/>
										</div>
										<div className={s.sidePanelItemContent}>
											<TextPlus.Body size="200" weight="semibold" tag="span">
												{title}
											</TextPlus.Body>
											<span className={s.sidePanelItemDesc}>{description}</span>
										</div>
									</Link>
								</li>
							)
						})}
					</ul>
				</NavPanel.Column>
			) : null}
		</NavPanel.Root>
	)
}

export interface NavProductProps {
	locale?: string
	url: string
	product: Products | string
	description: string
}

function NavProduct({ locale, url, product, description }: NavProductProps) {
	return (
		<NavigationMenu.Link asChild>
			<Link
				aria-label={`${product} - ${description}`}
				{...getLocalizedLinkProps(url, locale)}
				locale={locale ? locale : undefined}
				onClickCapture={() => {
					trackNavClickEvent(product, url, 'products')
				}}
				className={s.focusIndicator}
			>
				<div className={s.productWrapper}>
					<div className={s.productLogo}>
						<FlightIcon
							name={PRODUCT_LOGO_MAP.get(product) ?? 'hashicorp-fill-color'}
							className={
								PRODUCT_LOGO_EXCEPTION_CLASSNAMES[product]
									? s[PRODUCT_LOGO_EXCEPTION_CLASSNAMES[product]]
									: undefined
							}
						/>
					</div>
					<div className={s.productTextContent}>
						<span>{product}</span>
						<span className={s.productDesc}>{description}</span>
					</div>
				</div>
			</Link>
		</NavigationMenu.Link>
	)
}

export default ProductPanel
