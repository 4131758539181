import {
	createElement,
	type ComponentPropsWithRef,
	type ElementType,
} from 'react'
import { Text, type BaseTextProps } from './base'

interface DisplayProps<T extends ElementType> extends BaseTextProps<T> {
	size?: '100' | '200' | '300' | '400' | '500'
}

const DISPLAY_DEFAULT_WEIGHTS_PER_SIZE = {
	500: 'bold',
	400: 'semibold',
	300: 'semibold',
	200: 'semibold',
	100: 'medium',
} as const

export function Display<T extends ElementType>({
	size = '200',
	weight,
	...rest
}: DisplayProps<T> & Omit<ComponentPropsWithRef<T>, keyof DisplayProps<T>>) {
	return createElement(Text, {
		...rest,
		size,
		weight: weight ?? DISPLAY_DEFAULT_WEIGHTS_PER_SIZE[size],
		group: 'display',
	})
}

interface BodyProps<T extends ElementType> extends BaseTextProps<T> {
	size?: '100' | '200' | '300'
}

export function Body<T extends ElementType>({
	size = '200',
	weight = 'regular',
	...rest
}: BodyProps<T> & Omit<ComponentPropsWithRef<T>, keyof BodyProps<T>>) {
	return createElement(Text, {
		...rest,
		size,
		weight,
		group: 'body',
	})
}

interface CodeProps<T extends ElementType> extends BaseTextProps<T> {
	size?: '100' | '200' | '300'
}

export function Code<T extends ElementType>({
	size = '200',
	weight = 'regular',
	...rest
}: CodeProps<T> & Omit<ComponentPropsWithRef<T>, keyof CodeProps<T>>) {
	return createElement(Text, {
		...rest,
		size,
		weight,
		group: 'code',
	})
}
