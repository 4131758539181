import classNames from 'classnames'
import type { ReactNode } from 'react'
import Indicator from '../indicator'

interface LabelProps {
	className?: string
	controlId: string
	children: ReactNode
	isRequired?: boolean
	isOptional?: boolean
}

export default function Label({
	className,
	controlId,
	children,
	isRequired,
	isOptional,
	...rest
}: LabelProps) {
	return (
		<label
			className={classNames(
				'hds-form-label',
				'hds-typography-body-200',
				'hds-font-weight-semibold',
				className
			)}
			htmlFor={controlId}
			{...rest}
		>
			{children}
			<Indicator isRequired={isRequired} isOptional={isOptional} />
		</label>
	)
}
