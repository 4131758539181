import { type ReactNode } from 'react'
import type {
	Theme,
	LinkType,
	ThemeVariant,
} from '@hashicorp/react-button/types'
import { ButtonColor } from './types'
import { IconCornerRightDown16 } from '@hashicorp/flight-icons/svg-react/corner-right-down-16'
import { IconArrowRight16 } from '@hashicorp/flight-icons/svg-react/arrow-right-16'
import { IconExternalLink16 } from '@hashicorp/flight-icons/svg-react/external-link-16'
import { IconDownload16 } from '@hashicorp/flight-icons/svg-react/download-16'

export const COLORS = [
	'primary',
	'primary-black',
	'primary-white',
	'secondary', // deprecated; maps to secondary-white
	'secondary-high-contrast', // alternates between secondary-black and secondary-white based on theme
	'secondary-white',
	'secondary-black',
	'tertiary',
	'critical',
	'boundary',
	'consul',
	'nomad',
	'packer',
	'terraform',
	'vagrant',
	'vault',
	'waypoint',
	'hashicorp', // alias to primary
] as const

export function determineColor(theme?: Theme): ButtonColor {
	const variant: ThemeVariant = theme?.variant ? theme.variant : 'primary'

	if (variant === 'primary') {
		switch (theme?.brand) {
			case 'hashicorp':
				return 'primary'
			case 'boundary':
			case 'consul':
			case 'nomad':
			case 'packer':
			case 'terraform':
			case 'vagrant':
			case 'vault':
			case 'waypoint':
				return theme.brand
			case 'neutral':
				return 'secondary-high-contrast'
			default:
				return 'primary'
		}
	}

	if (variant === 'secondary') {
		return 'secondary'
	}

	if (
		variant === 'tertiary' ||
		variant === 'tertiary-neutral' ||
		variant === 'ghost'
	) {
		return 'tertiary'
	}

	throw new Error(
		`Unable to determine HDS Button color from theme: ${JSON.stringify(theme)}`
	)
}

export function determineIconFromLinktype(linkType: LinkType): ReactNode {
	switch (linkType) {
		case 'inbound':
			return (
				<IconArrowRight16
					style={{ display: 'block' }}
					width="100%"
					height="100%"
				/>
			)
		case 'outbound':
			return (
				<IconExternalLink16
					style={{ display: 'block' }}
					width="100%"
					height="100%"
				/>
			)
		case 'download':
			return (
				<IconDownload16
					style={{ display: 'block' }}
					width="100%"
					height="100%"
				/>
			)
		case 'anchor':
			return (
				<IconCornerRightDown16
					style={{ display: 'block' }}
					width="100%"
					height="100%"
				/>
			)
	}
}
