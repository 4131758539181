import type { ReactNode } from 'react'
import classNames from 'classnames'

interface HelperTextProps {
	children: ReactNode
	className?: string
	controlId?: string
}

export default function HelperText({
	children,
	className,
	controlId,
	...rest
}: HelperTextProps) {
	return (
		<div
			className={classNames(
				'hds-form-helper-text',
				'hds-typography-body-100',
				'hds-font-weight-regular',
				className
			)}
			id={controlId ? `helper-text-${controlId}` : undefined}
			{...rest}
		>
			{children}
		</div>
	)
}
