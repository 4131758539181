import React from 'react'
import '../pages/style.css'
import '@hashicorp/react-hds/src/style.scss'
import '@hashicorp/platform-util/nprogress/style.css'
import 'lib/keyboard-focus'
import classNames from 'classnames'
import Router from 'next/router'
import { Provider as ReactWrapBalancerProvider } from 'react-wrap-balancer'
import { LazyMotion } from 'framer-motion'
import StandardLayout from 'layouts/standard'
import NProgress from '@hashicorp/platform-util/nprogress'
import AlertBanner from '@hashicorp/react-alert-banner'
import AppGlobals from 'components/app-globals'
import LocalizedTranslationToast from 'components/localized-translation-toast'
import SkipLink from 'components/skip-link'
import font, {
	cssVariableOverrideClass,
} from '@web/global-styles/src/font/hashicorp-sans'

import { NextIntlClientProvider } from 'next-intl'
import Head from 'next/head'
import { EnFallbackEventLogger } from 'app/[locale]/components/en-fallback-event-logger'

NProgress({ Router })

const isOnClient = typeof window !== 'undefined'
const isNotInProd = process.env.HASHI_ENV !== 'production'
const shouldUseAxe = isOnClient && isNotInProd

if (shouldUseAxe) {
	import('react-dom').then((ReactDOM) => {
		import('@axe-core/react').then((axeMod) => {
			const axe = axeMod.default
			axe(React, ReactDOM, 1000)
		})
	})
}

function App({ Component, pageProps, layoutData, locale, pathname }) {
	const Layout = Component.layout ?? StandardLayout
	const nonEnLocale = locale !== 'en'
	return (
		<NextIntlClientProvider
			locale={locale as string}
			messages={pageProps.messages}
		>
			{/* All `pages` router pages only support English at present */}
			<LocalizedTranslationToast
				onFallbackPage={true}
				currentLocale={locale}
				pageLocales={['en']}
			/>
			{nonEnLocale && (
				<>
					<Head>
						<link
							rel="alternate"
							hrefLang="x-default"
							href={`https://hashicorp.com${pathname}`}
						/>
						<link
							rel="alternate"
							hrefLang="en"
							href={`https://hashicorp.com/en${pathname}`}
						/>
					</Head>
					<EnFallbackEventLogger locale={locale} />
				</>
			)}
			<ReactWrapBalancerProvider>
				<LazyMotion
					features={() =>
						import('lib/framer-motion/features').then((mod) => mod.default)
					}
				>
					<AppGlobals>
						{/* Returning `alertBanner` from `getStaticProps` will automatically populate and
      server render the alert banner into the layout here. */}
						<SkipLink />
						{pageProps.alertBanner && (
							<AlertBanner
								{...pageProps.alertBanner}
								// Asana - Debt: https://app.asana.com/0/1100423001970639/1149498115840724/f
								product={pageProps.alertBanner.theme.toLowerCase()}
							/>
						)}
						<div
							className={classNames(font.variable, cssVariableOverrideClass)}
						>
							<Layout
								{...(layoutData && { data: layoutData })}
								product={pageProps?.product}
								appearance={pageProps?.appearance}
								locale={locale}
							>
								<Component {...pageProps} {...layoutData} />
							</Layout>
						</div>
					</AppGlobals>
				</LazyMotion>
			</ReactWrapBalancerProvider>
		</NextIntlClientProvider>
	)
}

App.getInitialProps = async ({ Component, ctx }) => {
	const layoutQuery: Parameters<typeof cmsQuery> = Component.layout
		? Component.layout?.cmsQueryParams ?? null
		: StandardLayout.cmsQueryParams

	const locale = ctx.query.locale ?? 'en'

	const { cmsQuery } = await import('@web/cms')
	const layoutData = layoutQuery
		? (
				await cmsQuery({
					...layoutQuery[0],
					variables: { locale },
				})
		  )?.data ?? {}
		: {}

	let pageProps = {}

	if (Component.getInitialProps) {
		pageProps = await Component.getInitialProps(ctx)
	}

	const pathname = ctx.asPath.substring(3)

	return {
		pageProps,
		layoutData,
		locale,
		pathname,
	}
}

export default App
