import {
	useId,
	type ComponentProps,
	type HTMLProps,
	type ReactNode,
} from 'react'
import Field from '../field'
import Fieldset from '../fieldset'
import classNames from 'classnames'

interface RadioBaseProps {
	id: string
	required?: boolean
	field: HTMLProps<HTMLInputElement>
	className?: string
	testingKey?: string
}

export function RadioBase({
	id,
	required,
	field,
	className,
	testingKey,
}: RadioBaseProps) {
	return (
		<input
			id={id}
			type="radio"
			className={classNames('hds-form-radio', className)}
			required={required}
			data-testid={testingKey}
			{...field}
		/>
	)
}

interface RadioFieldProps {
	isInvalid?: boolean
	isLoading?: boolean
	isRequired?: boolean
	isOptional?: boolean
	id?: string
	label?: ReactNode
	helperText?: ReactNode
	error?: ReactNode
	field: HTMLProps<HTMLInputElement>
	className?: string
	testingKey?: string
}

export default function RadioField({
	isInvalid,
	isLoading,
	isRequired,
	isOptional,
	id,
	label,
	helperText,
	error,
	field,
	className,
	testingKey,
	...rest
}: RadioFieldProps) {
	const generatedId = useId()
	const inputId = id ?? generatedId

	return (
		<Field
			label={label}
			helperText={helperText}
			error={error}
			isRequired={isRequired}
			isOptional={isOptional}
			id={inputId}
			layout="flag"
			className={className}
		>
			<RadioBase
				id={inputId}
				required={isRequired}
				field={{ ...field, 'aria-describedby': `error-${id}` }}
				testingKey={testingKey}
				{...rest}
			/>
		</Field>
	)
}

interface RadioGroupProps extends ComponentProps<typeof Fieldset> {
	children: ReactNode
}

function RadioGroup({
	layout = 'vertical',
	legend,
	isOptional,
	isRequired,
	children,
}: RadioGroupProps) {
	return (
		<Fieldset
			layout={layout}
			legend={legend}
			isOptional={isOptional}
			isRequired={isRequired}
		>
			{children}
		</Fieldset>
	)
}

function RadioGroupField(props: RadioFieldProps) {
	return (
		<RadioField
			{...props}
			testingKey={props.testingKey}
			className={classNames('hds-form-group__control-field', props.className)}
		/>
	)
}

export const Group = {
	Root: RadioGroup,
	Field: RadioGroupField,
}
