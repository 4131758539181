import { type ForwardRefExoticComponent, forwardRef } from 'react'
import HDSButton from '@hashicorp/react-hds/src/components/button'
import type { ButtonProps as HDSButtonProps } from '@hashicorp/react-hds/src/components/button'
import styles from './styles.module.scss'
import classNames from 'classnames'

const COLORS = [
	'primary',
	'primary-black',
	'primary-white',
	'secondary', // deprecated; maps to secondary-white
	'secondary-high-contrast', // alternates between secondary-black and secondary-white based on theme
	'secondary-white',
	'secondary-black',
	'tertiary',
	'critical',
	'boundary',
	'consul',
	'nomad',
	'packer',
	'terraform',
	'vagrant',
	'vault',
	'waypoint',
	'hashicorp', // alias to primary
] as const

type ButtonColor = (typeof COLORS)[number]

interface HDSPlusButtonProps extends Omit<HDSButtonProps, 'color'> {
	color?: ButtonColor
	locale?: string
}

// Map of colors to HDSButtonProps colors
const colorMap: Record<ButtonColor, HDSButtonProps['color']> = {
	primary: 'primary',
	secondary: 'custom',
	tertiary: 'tertiary',
	critical: 'critical',
	'primary-black': 'custom',
	'primary-white': 'custom',
	'secondary-high-contrast': 'custom',
	'secondary-white': 'custom',
	'secondary-black': 'custom',
	boundary: 'custom',
	consul: 'custom',
	nomad: 'custom',
	packer: 'custom',
	terraform: 'custom',
	vagrant: 'custom',
	vault: 'custom',
	waypoint: 'custom',
	hashicorp: 'primary', // alias to primary
}

function determineColor(color: ButtonColor): HDSButtonProps['color'] {
	return colorMap[color]
}

const extractColor = (color: ButtonColor): ButtonColor => {
	if (color === 'hashicorp') {
		return 'primary'
	}
	const isLegacySecondaryColor = color === 'secondary'

	return isLegacySecondaryColor ? 'secondary-white' : color
}

const HDSPlusButton = forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	HDSPlusButtonProps
>(({ color = 'primary', className, locale, ...rest }, ref) => {
	const determinedColor = determineColor(color)
	let customColor: string | undefined

	if (determinedColor === 'custom') {
		customColor = styles[`mds-button--color-${extractColor(color)}`]
	}

	return (
		<HDSButton
			color={determinedColor}
			className={classNames(customColor, className)}
			locale={locale}
			{...rest}
			ref={ref as ForwardRefExoticComponent<HTMLButtonElement>}
		/>
	)
})

HDSPlusButton.displayName = 'HDSPlusButton'

export { HDSPlusButton, type HDSPlusButtonProps, COLORS }
