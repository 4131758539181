import classNames from 'classnames'
import { HTMLInputTypeAttribute, HTMLProps, ReactNode, useId } from 'react'
import Field from '../field'

export interface TextInputBaseProps {
	/**
	 * Sets the native HTML `type` of the `<input>`.
	 */
	type?: HTMLInputTypeAttribute

	/**
	 * Applies an “invalid” appearance to the control but doesn’t modify its logical validity.
	 */
	isInvalid?: boolean

	/**
	 * When true, it shows a loading indicator instead of a magnifying glass; only applicable when `type="search"`.
	 */
	isLoading?: boolean
	isRequired?: boolean
	className?: string
	id?: string

	/**
	 * Can be used to supply any props that the native `<input>` element supports (such as `value`, `placeholder`, in addition to events like `onChange` and `onBlur`)
	 */
	field: HTMLProps<HTMLInputElement>

	/**
	 * Sets the size of the input to match either medium Buttons or large Buttons.
	 */
	size?: 'medium' | 'large'
	error?: ReactNode
}

export function TextInputBase({
	type,
	isInvalid,
	isLoading,
	isRequired,
	className,
	id,
	field,
	size = 'medium',
	error,
	...rest
}: TextInputBaseProps) {
	const generatedId = useId()
	const inputId = id ?? generatedId

	return (
		<input
			id={inputId}
			type={type}
			className={classNames(
				'hds-form-text-input',
				`hds-form-text-input--size-${size}`,
				'hds-font-weight-regular',
				{
					['hds-typography-body-200']: size === 'medium',
					['hds-typography-body-300']: size === 'large',
					['hds-form-text-input--is-invalid']: isInvalid,
					['hds-form-text-input--is-loading']: isLoading,
				},
				className
			)}
			required={isRequired}
			aria-describedby={`${error ? 'error' : 'helper-text'}-${id}`}
			{...field}
			{...rest}
		/>
	)
}

interface TextInputFieldProps {
	/**
	 * Sets the native HTML `type` of the `<input>`.
	 */
	type?: HTMLInputTypeAttribute

	/**
	 * Applies an “invalid” appearance to the control but doesn’t modify its logical validity.
	 */
	isInvalid?: boolean

	/**
	 * When true, it shows a loading indicator instead of a magnifying glass; only applicable when `type="search"`.
	 */
	isLoading?: boolean

	/**
	 * Appends a `Required` indicator next to the label text and sets the `required` attribute on the control when user input is required.
	 */
	isRequired?: boolean

	/**
	 * Appends an `Optional` indicator next to the label text when user input is optional.
	 */
	isOptional?: boolean

	/**
	 * Input control’s ID attribute.
	 *
	 * By default, the ID is automatically generated by the component. Use this argument to pass a custom ID.
	 */
	id?: string

	/**
	 * Container that renders its content inside the `<label>` element. The content can be a simple string or a `ReactNode`, in which case it inherits the text style.
	 *
	 * The `for` attribute of the label is automatically generated using the `controlId` value of the control.
	 */
	label?: ReactNode

	/**
	 * Container that renders its content inside the "helper text" block. The content can be a simple string or a `ReactNode`, in which case it inherits the text style.
	 *
	 * The `id` attribute of the message is automatically generated using the `controlId` value of the control.
	 */
	helperText?: ReactNode

	/**
	 * Container that renders its content inside the "error" block. The content can be a simple string or a `ReactNode`, in which case it inherits the text style.
	 *
	 * The `id` attribute of the error message is automatically generated using the `controlId` value of the control.
	 */
	error?: ReactNode

	/**
	 * Can be used to supply any props that the native `<input>` element supports (such as `value`, `placeholder`, in addition to events like `onChange` and `onBlur`)
	 */
	field: HTMLProps<HTMLInputElement>
	className?: string

	/**
	 * Sets the size of the input to match either medium Buttons or large Buttons.
	 */
	size?: 'medium' | 'large'
}

export default function TextInputField({
	type = 'text',
	isInvalid,
	isLoading,
	isRequired,
	isOptional,
	id,
	label,
	helperText,
	error,
	field,
	className,
	size = 'medium',
	...rest
}: TextInputFieldProps) {
	const generatedId = useId()
	const inputId = id ?? generatedId

	return (
		<Field
			label={label}
			helperText={helperText}
			error={error}
			isRequired={isRequired}
			isOptional={isOptional}
			id={inputId}
			layout="vertical"
			className={className}
		>
			<TextInputBase
				id={inputId}
				type={type}
				isInvalid={isInvalid}
				isLoading={isLoading}
				isRequired={isRequired}
				field={field}
				size={size}
				{...rest}
			/>
		</Field>
	)
}
