import { type ForwardRefExoticComponent, forwardRef } from 'react'
import HDSStandaloneLink from '@hashicorp/react-hds/src/components/standalone-link'
import type { StandaloneLinkProps as HDSStandaloneLinkProps } from '@hashicorp/react-hds/src/components/standalone-link'
import styles from './styles.module.css'
import classNames from 'classnames'

const COLORS = ['primary', 'secondary', 'secondary-inverted'] as const

type StandaloneLinkColor = (typeof COLORS)[number]

interface HDSPlusStandaloneLinkProps
	extends Omit<HDSStandaloneLinkProps, 'color'> {
	color?: StandaloneLinkColor
	locale?: string
}

// Map of colors to HDSStandaloneLink colors
const colorMap: Record<StandaloneLinkColor, HDSStandaloneLinkProps['color']> = {
	primary: 'primary',
	secondary: 'secondary',
	'secondary-inverted': 'custom',
}

function determineColor(
	color: StandaloneLinkColor
): HDSStandaloneLinkProps['color'] {
	return colorMap[color]
}

const HDSPlusStandaloneLink = forwardRef<
	HTMLAnchorElement,
	HDSPlusStandaloneLinkProps
>(({ color = 'primary', className, locale, ...rest }, ref) => {
	const determinedColor = determineColor(color)
	let customColor: string | undefined

	if (determinedColor === 'custom') {
		customColor = styles[`mds-link-standalone--color-${color}`]
	}
	return (
		<HDSStandaloneLink
			color={determinedColor}
			className={classNames(customColor, className)}
			{...rest}
			locale={locale}
			ref={ref as ForwardRefExoticComponent<HTMLAnchorElement>}
		/>
	)
})

HDSPlusStandaloneLink.displayName = 'HDSPlusStandaloneLink'

export { HDSPlusStandaloneLink, type HDSPlusStandaloneLinkProps, COLORS }
