import { type ReactNode } from 'react'
import { usePathname } from 'lib/i18n/routing'
import Globals from './component'
import PostHogProviderPagesRouter from 'components/posthog-provider/pages-router'

interface Props {
	children: ReactNode
}

export default function AppGlobals({ children }: Props) {
	const pathname = usePathname()

	return (
		<PostHogProviderPagesRouter>
			<Globals pathname={pathname}>{children}</Globals>
		</PostHogProviderPagesRouter>
	)
}
