import { parseUrl } from '../parse-url'
import { allSupportedLocales } from '../supported-locales'

export const getLocalizedLinkProps = (
	url: string,
	locale?: string,
	forceExternal?: boolean
): {
	href: string
	hrefLang: string | undefined
} => {
	const parsedHref = parseUrl(url, forceExternal).href

	// example: /en
	const hrefIsLocalizedHomepage = allSupportedLocales.includes(
		parsedHref.substring(1)
	)

	// true example: /en/solutions
	// false example: /entry-point
	const hrefIsLocalized =
		allSupportedLocales.includes(parsedHref.substring(1, 3)) &&
		parsedHref[3] === '/'

	// If the href is already localized:
	// - do not format the href
	// - use the locale from the href for the hrefLang
	if (hrefIsLocalizedHomepage || hrefIsLocalized) {
		return {
			href: parsedHref,
			hrefLang: parsedHref.substring(1, 3),
		}
	}

	if (parsedHref.startsWith('/') && locale) {
		return {
			href: `/${locale}${parsedHref}`,
			hrefLang: locale,
		}
	}

	return {
		href: parsedHref,
		hrefLang: undefined,
	}
}
