import { useCallback } from 'react'
import classNames from 'classnames'
import { vercelSplit } from '../../../../lib/v'
import Link from 'next/link'
import { getLocalizedLinkProps } from '../../../../lib/get-localized-link-props'
import { trackNavClickEvent } from '../../../../lib/track-nav-click-event'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import s from './style.module.css'

export interface PromoProps {
	locale?: string
	title: string
	description: string
	linkUrl: string
	linkTitle: string
	slug?: string
	icon?: string
	theme: string
	hasSidePanel?: boolean
}

export default function Promo({
	locale,
	title,
	description,
	linkUrl,
	linkTitle,
	slug,
	icon,
	theme,
	hasSidePanel,
}: PromoProps) {
	const handleClick = useCallback((title, linkUrl, linkTitle, slug) => {
		if (window && window.analytics) {
			window.analytics.track('Nav Promo', {
				url: linkUrl,
				title: title,
				callout: linkTitle,
			})

			trackNavClickEvent(title, linkUrl, slug)
		}
	}, [])

	return (
		<div
			className={classNames(s.promo, {
				[s.hasSide]: hasSidePanel,
				[s.noSide]: !hasSidePanel,
			})}
		>
			{icon && (
				<div
					className={classNames(s.icon, {
						[s.hashidays]: vercelSplit(theme) === 'hashidays',
						[s.hashiconf]: vercelSplit(theme) === 'hashiconf',
					})}
				>
					<FlightIcon name={vercelSplit(icon)} />
				</div>
			)}
			<div className={s.rightContent}>
				<div>
					<div
						className={classNames(s.title, {
							[s.hashidays]: vercelSplit(theme) === 'hashidays',
							[s.hashiconf]: vercelSplit(theme) === 'hashiconf',
						})}
					>
						{title}
					</div>
					<div className={s.description}>{description}</div>
				</div>
				<NavigationMenu.Link asChild>
					<Link
						locale={locale ? locale : undefined}
						{...getLocalizedLinkProps(linkUrl, locale)}
						className={s.link}
						onClickCapture={() => handleClick(title, linkUrl, linkTitle, slug)}
					>
						{linkTitle}
						<FlightIcon name="external-link" />
					</Link>
				</NavigationMenu.Link>
			</div>
		</div>
	)
}
