import { useState } from 'react'

export const useNavAnimation = (activePanelId: string) => {
	const [listRef, setListRef] = useState<HTMLUListElement | null>(null)
	const [offset, setOffset] = useState<number | null>(null)

	const handleTriggerRef = (
		activeTrigger: HTMLButtonElement,
		radixId: string
	) => {
		const isCurrentTrigger = activePanelId === radixId
		if (!isCurrentTrigger) return

		if (!activePanelId || !listRef || !activeTrigger) {
			setOffset(null)
			return
		}

		const listWidth = listRef.offsetWidth + listRef.parentElement.offsetLeft
		const trig = activeTrigger.parentElement
		const trigCenter = trig.offsetLeft + trig.offsetWidth
		const translateRatio = trigCenter / listWidth
		const newOffset = translateRatio * listWidth
		setOffset(newOffset)
	}

	return { setListRef, offset, handleTriggerRef }
}
