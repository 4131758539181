import type { ReactNode } from 'react'
import classNames from 'classnames'
import Indicator from '../indicator'

interface LegendProps {
	className?: string
	isRequired?: boolean
	isOptional?: boolean
	children: ReactNode
}

export default function Legend({
	className,
	isRequired,
	isOptional,
	children,
	...rest
}: LegendProps) {
	return (
		<legend
			className={classNames(
				'hds-form-legend',
				'hds-typography-body-200',
				'hds-font-weight-semibold',
				className
			)}
			{...rest}
		>
			{children}
			<Indicator isRequired={isRequired} isOptional={isOptional} />
		</legend>
	)
}
