import classNames from 'classnames'
import FlightIcon from '../flight-icon'
import type { ReactNode } from 'react'

export const SIZES = ['small', 'medium', 'large'] as const
export const TYPES = ['filled', 'inverted', 'outlined'] as const
export const COLORS = [
	'neutral',
	'neutral-dark-mode',
	'highlight',
	'success',
	'warning',
	'critical',
] as const

export interface BadgeProps {
	/** Use this parameter to show an icon. */
	icon?: ReactNode

	/** This indicates if the button will only contain an icon. An internal check is in place to ensure that accessible text is still applied to the component. */
	iconOnly?: boolean

	/** The text of the Badge or value of the screen-reader only element if isIconOnly is set to true. If no text value is defined an error will be thrown. */
	text: string

	size?: (typeof SIZES)[number]
	type?: (typeof TYPES)[number]
	color?: (typeof COLORS)[number]
	className?: string
}

export default function Badge({
	icon,
	iconOnly,
	text,
	size = 'medium',
	type = 'filled',
	color = 'neutral',
	className,
	...rest
}: BadgeProps) {
	return (
		<div
			className={classNames(
				'hds-badge',
				`hds-badge--size-${size}`,
				`hds-badge--type-${type}`,
				`hds-badge--color-${color}`,
				className
			)}
			{...rest}
		>
			{icon && (
				<div className="hds-badge__icon">
					{typeof icon === 'string' ? (
						<FlightIcon name={icon} size={16} stretched />
					) : (
						icon
					)}
				</div>
			)}
			{iconOnly && icon ? (
				<span className="sr-only">{text}</span>
			) : (
				<div className="hds-badge__text">{text}</div>
			)}
		</div>
	)
}
