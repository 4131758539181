import classNames from 'classnames'
import type { ReactNode } from 'react'
import { IconAlertDiamondFill16 } from '@hashicorp/flight-icons/svg-react/alert-diamond-fill-16'

interface ErrorMessageProps {
	children: ReactNode
}

function ErrorMessage({ children, ...rest }: ErrorMessageProps) {
	return (
		<p className="hds-form-error__message" {...rest}>
			{children}
		</p>
	)
}

interface ErrorProps {
	children: ReactNode
	className?: string
	controlId?: string
}

export default function Error({
	children,
	className,
	controlId,
	...rest
}: ErrorProps) {
	return (
		<div
			className={classNames(
				'hds-form-error',
				'hds-typography-body-100',
				'hds-font-weight-medium',
				className
			)}
			id={controlId ? `error-${controlId}` : undefined}
			{...rest}
		>
			<IconAlertDiamondFill16 className="hds-form-error__icon" />
			<div className="hds-form-error__content">
				<ErrorMessage>{children}</ErrorMessage>
			</div>
		</div>
	)
}
