'use client'

import { useEffect, useState } from 'react'
import * as Toast from '@radix-ui/react-toast'
import v from '@vercel/analytics'
import { allSupportedLocales } from '@web/utils/supported-locales'
import Button from '@hashicorp/react-hds/src/components/button'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import { TextPlus } from '@hashicorp/react-hds/src/components/text'
import CloseButton from '@hashicorp/react-close-button'
import { TRANSLATION_ALERT_STATE_MAP } from 'lib/i18n/helpers/constants'
import { usePathname } from 'lib/i18n/routing'
import LocaleSwitcher from 'components/locale-switcher'
import s from './style.module.css'

const DISMISSED_KEY_NAME = 'hc_translation_alert_dismissed'

interface LocalizedTranslationToastProps {
	onFallbackPage?: boolean
	currentLocale: string
	pageLocales: string[]
}

export default function LocalizedTranslationToast({
	onFallbackPage,
	currentLocale,
	pageLocales,
}: LocalizedTranslationToastProps) {
	const [open, setOpen] = useState(false)
	const [userLanguage, setUserLanguage] = useState(null)
	const [alertLanguage, setAlertLanguage] = useState('en')
	const pathname = usePathname()
	const userLanguageSupportedByPage = pageLocales.includes(userLanguage)
	const userLanguageSupportedBySite = allSupportedLocales.includes(userLanguage)

	useEffect(() => {
		if (userLanguage === null) {
			setUserLanguage(navigator.languages[0].split('-')[0])
			return
		}

		/**
		 * Return early if:
		 * - the user has already dismissed the notification
		 * - there is no alert data passed
		 * - the user's preferred language matches the page's
		 *   language, and that language is supported by
		 *   the page
		 */
		if (localStorage.getItem(DISMISSED_KEY_NAME)) {
			setOpen(false)
			return
		}

		if (currentLocale === userLanguage && userLanguageSupportedByPage) {
			setOpen(false)
			return
		}

		/**
		 * If falling back to English and the user is using
		 * an English browser language, do not show the toast
		 */
		if (onFallbackPage && userLanguage === 'en') {
			setOpen(false)
			return
		}

		// Change alert language to user language if supported
		if (userLanguageSupportedBySite) {
			setAlertLanguage(userLanguage)
		}

		setOpen(true)
	}, [userLanguage])

	// Don't show the toast by default
	let data = null

	/**
	 * If the current locale is not supported by the
	 * current page's locales tell the user as much.
	 *
	 * Otherwise, inform them there _is_ a translation,
	 * but only if the current locale isn't their
	 * preferred language
	 */
	if (!userLanguageSupportedBySite) {
		data = TRANSLATION_ALERT_STATE_MAP[alertLanguage].languageUnsupported
	} else if (!userLanguageSupportedByPage) {
		data = TRANSLATION_ALERT_STATE_MAP[alertLanguage].translationUnavailable
	} else if (currentLocale !== userLanguage) {
		data = TRANSLATION_ALERT_STATE_MAP[alertLanguage].translationAvailable
	}

	if (!data) return null

	return (
		<Toast.Provider swipeDirection="right" duration={Infinity}>
			<Toast.Root asChild open={open} onOpenChange={setOpen}>
				<div className={s.toast}>
					<FlightIcon
						className={s.icon}
						size={24}
						name="alert-triangle"
						color="var(--token-color-foreground-warning-on-surface)"
					/>

					<Toast.Title asChild className={s.title}>
						<TextPlus.DisplayExpressive size="100" weight="bold">
							{data.title}
						</TextPlus.DisplayExpressive>
					</Toast.Title>

					<Toast.Close asChild>
						<CloseButton
							className={s.closeButton}
							ariaLabel="Close localized page notification"
							onClick={() => {
								v.track('Localized translation toast closed', {
									locale: userLanguage,
								})
								localStorage.setItem(DISMISSED_KEY_NAME, 'true')
							}}
						/>
					</Toast.Close>

					<Toast.Description asChild className={s.description}>
						<TextPlus.Body>{data.description}</TextPlus.Body>
					</Toast.Description>

					{data.cta ? (
						<Button
							className={s.cta}
							text={data.cta.text}
							href={`/${data.cta.locale || userLanguage}${pathname}`}
							onClick={() => {
								v.track('Localized translation viewed from toast', {
									locale: userLanguage,
								})
							}}
						/>
					) : null}

					{data.localeSwitcher ? (
						<LocaleSwitcher
							variant="primary"
							listPosition="top-left"
							buttonText={data.localeSwitcher.text}
							localesAvailable={pageLocales}
						/>
					) : null}
				</div>
			</Toast.Root>
			<Toast.Viewport asChild>
				<div className={s.viewport}></div>
			</Toast.Viewport>
		</Toast.Provider>
	)
}
